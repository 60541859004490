import styled from 'styled-components'
import { Form } from 'formik'
import { Colors, Breakpoints } from 'styles'
import { Button } from '@mui/material'

export const Wrapper = styled.div`
  max-width: 480px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

export const StepHeader = styled.div`
  align-self: center;
  text-align: center;
  color: var(--800);
  .title {
    font-size: 32px;
  }
  .subtitle {
    font-weight: 300;
  }
`

export const StepContent = styled.div`
  flex-grow: 1;
`

export const StepNav = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.mobilePortrait}) {
    flex-direction: column;
    gap: 12px;
    .btn-next {
      order: -1;
    }
  }
`

export const FormBox = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

export const CustomButton = styled(Button)`
  color: ${Colors.brand.primary};
  font-family: 'Poppins';
  font-size: 16px;
  border: 1px solid ${Colors.brand.primary}50;
  :hover {
    background-color: ${Colors.brand.light};
    border: 1px solid ${Colors.brand.primary};
  }
`
