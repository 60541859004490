import { FC } from 'react'
import { Formik, Form, FormikHelpers, Field, FieldProps } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { RE_NON_DIGIT } from 'common/utils/constants'
import { formatCPF, formatDate, formatPhone } from 'src/utils/formatters'
import { Half2Icon } from '@radix-ui/react-icons'
import { Navbar } from '@layouts/navbar'
import { Footer } from '@layouts/footer'
import { Input } from '@components/ui/input'
import { Button } from '@components/ui/button'
import { Label } from '@components/ui/label'
import { useToast } from '@components/ui/use-toast'
import { ToastAction } from '@components/ui/toast'
import { postMedCred } from '@services/medcred'
import Link from 'next/link'
import { isCpf, isValidBirth } from 'src/utils/validator'
import isEmail from 'validator/lib/isEmail'

type Values = {
  name: string
  cpf: string
  email: string
  phone: string
  birth: string
  occupation: string
  loading: boolean
}

// CONSTS

const initialValues = {
  name: '',
  cpf: '',
  email: '',
  phone: '',
  birth: '',
  occupation: '',
}

const validationSchema = [
  Yup.object({
    name: Yup.string().required(`Nome é obrigatório`),
    cpf: Yup.string()
      .required(`CPF é obrigatório`)
      .test(
        'is-valid',
        (message: any) => `CPF inválido`,
        (value) =>
          value
            ? isCpf(value.replace(RE_NON_DIGIT, ''))
            : new Yup.ValidationError('Invalid value')
      ),
    email: Yup.string()
      .required(`Email é obrigatório`)
      .test(
        'is-valid',
        (message: any) => `Email inválido`,
        (value) =>
          value
            ? isEmail(value, {
                allow_ip_domain: true,
                domain_specific_validation: true,
              })
            : new Yup.ValidationError('Invalid value')
      ),
    phone: Yup.string()
      .required(`Celular é obrigatório`)
      .min(15, 'Celular deve ter 11 números, incluindo DDD.'),
    birth: Yup.string()
      .required(`Data de nascimento é obrigatória`)
      .test(
        'is-valid',
        (message: any) => `Verificar data de nascimento`,
        (value) =>
          value ? isValidBirth(value) : new Yup.ValidationError('Invalid value')
      ),
    occupation: Yup.string().required(`Cargo é obrigatório`),
  }),
]

// SECTIONS

const Hero: FC = () => {
  return (
    <section className="bg-white to-white px-5 py-16">
      <div className="container max-w-5xl p-0">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Suas conquistas são nossa prioridade
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            As melhores condições de crédito para profissionais da saúde estão
            aqui. Faça um simulação agora e descubra.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              href="#form-section"
              className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Simular
            </Link>
            <Link
              href="#features-section"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Saiba mais <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

const Features = () => {
  const features = [
    {
      name: 'Taxas Competitivas',
      description:
        'Oferecemos taxas de juros altamente competitivas, para que você possa alcançar seus objetivos financeiros sem comprometer seu orçamento.',
      icon: Half2Icon,
    },
    {
      name: 'Prazos Flexíveis',
      description:
        'Adaptamos nossos prazos de pagamento de acordo com suas necessidades, proporcionando maior tranquilidade para investir em sua carreira ou projetos pessoais.',
      icon: Half2Icon,
    },
    {
      name: 'Segurança e Confiança',
      description:
        'Com anos de experiência no setor financeiro, somos uma escolha segura e confiável para suas necessidades de crédito.',
      icon: Half2Icon,
    },
  ]

  return (
    <div id="features-section" className="bg-white py-16">
      <div className="mx-auto max-w-6xl sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
            <div className="lg:row-start-2 lg:max-w-md">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Conheça nossa linha de crédito especial para a área da saúde.
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Nós entendemos o esforço e dedicação que os profissionais da
                saúde dedicam a seus pacientes. Por isso, oferecemos soluções de
                crédito sob medida para você.
              </p>
            </div>
            <img
              src="https://storage.googleapis.com/souone_client/images/medcred-features-01.png"
              alt="Product screenshot"
              className="relative -z-20 min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none"
              width={2432}
              height={1442}
            />
            <div className="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">
              <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt className="ml-9 inline-block font-semibold text-white">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          {/* <div
            className="pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div> */}
        </div>
      </div>
    </div>
  )
}

const CallToAction = () => {
  return (
    <div className="relative bg-gray-900">
      <div className="relative h-80 overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
        <img
          className="h-full w-full object-cover"
          src="https://storage.googleapis.com/souone_client/images/medcred-cta-01.png"
          alt=""
        />
        <svg
          viewBox="0 0 926 676"
          aria-hidden="true"
          className="absolute -bottom-24 left-24 w-[57.875rem] transform-gpu blur-[118px]"
        >
          <path
            fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
            fillOpacity=".4"
            d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
          />
          <defs>
            <linearGradient
              id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
              x1="926.392"
              x2="-109.635"
              y1=".176"
              y2="321.024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#776FFF" />
              <stop offset={1} stopColor="#FF4694" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">
            Simule agora de onde você estiver
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Não pense duas vezes. Pense One.
          </p>
          <p className="mt-6 text-base leading-7 text-gray-300">
            Você recebe a resposta da sua solicitação no mesmo dia e ainda conta
            com uma análise personalizada, com taxas e condições que se encaixam
            melhor nesse momento.
          </p>
        </div>
      </div>
    </div>
  )
}

const SimulationForm = () => {
  const { toast } = useToast()

  const handleSubmit = async (
    values: Values,
    actions: FormikHelpers<Values>
  ) => {
    actions.setSubmitting(true)

    try {
      const user = await postMedCred({
        name: values.name,
        cpf: values.cpf.replace(RE_NON_DIGIT, ''),
        phone: values.phone.replace(RE_NON_DIGIT, ''),
        email: values.email,
        birth: moment(values.birth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        occupation: values.occupation,
      })

      actions.setSubmitting(false)

      toast({
        title: 'Solicitação enviada com sucesso.',
      })

      return actions.resetForm()
    } catch (error) {
      console.log(error)
      toast({
        variant: 'destructive',
        title: 'Tivemos um problema no envio ',
      })

      return actions.setSubmitting(false)
    }
  }

  return (
    <div id="form-section" className="bg-white px-5 py-16">
      <div className="container max-w-md p-0">
        <div className="my-8 grid gap-3">
          <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Envie sua solicitação.
          </p>
          <p className="text-base leading-7 text-slate-500">
            Você está à um clique da análise de crédito personalizada.
          </p>
        </div>
        <Formik
          initialValues={{
            ...initialValues,
            loading: false,
          }}
          validationSchema={validationSchema[0]}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, errors }) => (
            <Form className="flex flex-col gap-4">
              <Field name="name">
                {({ field, meta }: FieldProps) => (
                  <div className="grid w-full items-center gap-1.5">
                    <Label htmlFor="name">Nome completo</Label>
                    <Input
                      {...field}
                      className={meta.error ? 'border-red-500' : ''}
                      id="name"
                      type="name"
                      placeholder="Carolina Ribeiro"
                    />
                    {errors.name ? (
                      <div className="text-xs text-red-500">{errors.name}</div>
                    ) : null}
                  </div>
                )}
              </Field>
              <Field name="cpf">
                {({ field, meta }: FieldProps) => (
                  <div className="grid w-full items-center gap-1.5">
                    <Label htmlFor="cpf">CPF</Label>
                    <Input
                      {...field}
                      className={meta.error ? 'border-red-500' : ''}
                      id="cpf"
                      type="cpf"
                      placeholder="123.456.789-00"
                      onChange={(e) =>
                        setFieldValue('cpf', formatCPF(e.target.value))
                      }
                    />
                    {errors.cpf ? (
                      <div className="text-xs text-red-500">{errors.cpf}</div>
                    ) : null}
                  </div>
                )}
              </Field>
              <Field name="email">
                {({ field, meta }: FieldProps) => (
                  <div className="grid w-full items-center gap-1.5">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      {...field}
                      className={meta.error ? 'border-red-500' : ''}
                      id="email"
                      type="email"
                      placeholder="exemplo@email.com"
                      inputMode="email"
                    />
                    {errors.email ? (
                      <div className="text-xs text-red-500">{errors.email}</div>
                    ) : null}
                  </div>
                )}
              </Field>
              <Field name="phone">
                {({ field, meta }: FieldProps) => (
                  <div className="grid w-full items-center gap-1.5">
                    <Label htmlFor="phone">Celular</Label>
                    <Input
                      {...field}
                      className={meta.error ? 'border-red-500' : ''}
                      id="phone"
                      type="phone"
                      placeholder="(21) 91234-5678"
                      inputMode="tel"
                      onChange={(e) =>
                        setFieldValue('phone', formatPhone(e.target.value))
                      }
                    />
                    {errors.phone ? (
                      <div className="text-xs text-red-500">{errors.phone}</div>
                    ) : null}
                  </div>
                )}
              </Field>
              <Field name="birth">
                {({ field, meta }: FieldProps) => (
                  <div className="grid w-full items-center gap-1.5">
                    <Label htmlFor="birth">Nascimento</Label>
                    <Input
                      {...field}
                      className={meta.error ? 'border-red-500' : ''}
                      id="birth"
                      type="birth"
                      placeholder="01/10/1990"
                      inputMode="numeric"
                      onChange={(e) =>
                        setFieldValue('birth', formatDate(e.target.value))
                      }
                    />
                    {errors.birth ? (
                      <div className="text-xs text-red-500">{errors.birth}</div>
                    ) : null}
                  </div>
                )}
              </Field>
              <Field name="occupation">
                {({ field, meta }: FieldProps) => (
                  <div className="grid w-full items-center gap-1.5">
                    <Label htmlFor="occupation">Cargo</Label>
                    <Input
                      {...field}
                      className={meta.error ? 'border-red-500' : ''}
                      id="occupation"
                      type="occupation"
                      placeholder="Cardiologista"
                    />
                    {errors.occupation ? (
                      <div className="text-xs text-red-500">
                        {errors.occupation}
                      </div>
                    ) : null}
                  </div>
                )}
              </Field>
              <p className="text-xs">
                Eu li e estou ciente das condições de tratamento dos meus dados
                pessoais e do meu consentimento, quando aplicável, conforme
                descrito nos Termos de Uso.
              </p>
              <Button type="submit" loading={isSubmitting}>
                Solicitar simulação
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

// PAGE

export const MedCred: FC = () => {
  return (
    <>
      <Navbar bg="none" color="dark" />
      <Hero />
      <Features />
      <CallToAction />
      <SimulationForm />
      <Footer />
    </>
  )
}
