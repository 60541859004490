import React, { FC } from 'react'
import Image from 'next/legacy/image'
import { Container } from 'src/components'
import { Section, Wrapper } from './styles'

interface Props {
  title: string
  image: any
  priority?: boolean
}

export const Header: FC<Props> = ({ title, image, priority }) => {
  return (
    <Section>
      <Image
        src={image}
        layout="fill"
        objectFit="cover"
        priority={priority}
        alt="header-img"
      />
      <Container>
        <Wrapper>{title}</Wrapper>
      </Container>
    </Section>
  )
}
