import styled from 'styled-components'
import { Colors, Breakpoints } from 'styles'

export const Section = styled.div`
  padding: 120px 20px;
  background: ${Colors.brand.light};
`

export const Wrapper = styled.div`
  min-height: 560px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
`

export const ImgWrapper = styled.div``

export const TextBox = styled.div`
  max-width: 40%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${Colors.basic.white};
  border-radius: 16px 0px 0px 16px;
  @media (max-width: ${Breakpoints.mobileLandscape}) {
    max-width: 70%;
  }
`

export const TextTitle = styled.div`
  font-size: 40px;
  > * {
    width: max-content;
  }
  .bold {
    color: ${Colors.brand.primary};
    font-weight: 500;
  }
  @media (max-width: ${Breakpoints.mobileLandscape}) {
    display: none;
  }
`

export const TextBody = styled.div`
  color: ${Colors.basic.dark};
  letter-spacing: 0.05em;
  line-height: 2;
`
