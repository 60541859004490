import styled from 'styled-components'
import { Colors, Breakpoints } from 'styles'

export const Section = styled.div`
  padding: 80px 20px;
  .text {
    color: ${Colors.basic.ground};
    line-height: 2em;
    letter-spacing: 0.05em;
    font-weight: 300;
  }
  .h2 {
    font-weight: 400;
  }
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 60px;
  .b {
    grid-column: span 2;
  }
  @media (max-width: ${Breakpoints.mobileLandscape}) {
    grid-template-columns: 1fr;
    .b {
      grid-column: auto;
    }
  }
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .box-title {
    text-transform: uppercase;
    color: ${Colors.brand.primary};
    font-weight: 500;
    letter-spacing: 0.1em;
  }
`
