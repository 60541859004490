import styled from 'styled-components'
import { Colors, Breakpoints } from 'styles'

export const Main = styled.div`
  padding: 60px 20px;
  background: ${Colors.basic.cloud};
`

export const Container = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
`

export const Wrapper = styled.div`
  color: ${Colors.basic.ground};
  font-size: 15px;
`

export const Nav = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 1fr 0.25fr;
  @media (max-width: ${Breakpoints.mobileLandscape}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
  }
`

export const NavLogo = styled.a``

export const NavLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  @media (max-width: ${Breakpoints.mobileLandscape}) {
    gap: 40px;
  }
`

export const Link = styled.a`
  &:hover {
    cursor: pointer;
    opacity: 0.96;
  }
  transition: ease opacity 200ms;
`

export const NavSocial = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  a {
    cursor: pointer;
  }
`

export const Divider = styled.div`
  height: 1px;
  margin: 16px 0px;
  background: ${Colors.basic.gray};
`

export const Info = styled.div`
  margin: 32px 0px;
  font-size: 12px;
  font-weight: 300;
  text-align: justify;
`

export const Copywrite = styled.div`
  font-size: 12px;
  font-weight: 300;
  text-align: center;
`
