import styled, { css } from 'styled-components'
import { Colors } from 'styles'

interface IProps {
  option?: 'primary' | 'secondary'
}

const options = {
  primary: css`
    background: ${Colors.brand.primary};
    color: ${Colors.basic.white};
    border: 2px solid ${Colors.brand.primary};
  `,
  secondary: css`
    background: ${Colors.brand.white};
    color: ${Colors.brand.primary};
    border: 2px solid ${Colors.brand.primary};
  `,
}

export const Spinner = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid white;
  border-bottom: 2px solid ${Colors.brand.primary};
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const BtnNav = styled.button<IProps>`
  padding: 8px 32px;
  min-width: 160px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ option }) => (option ? options[option] : options.primary)}
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  border-radius: 48px;
  &:hover {
    cursor: pointer;
  }
  transition: ease background-color 100ms, color 100ms;
  :disabled {
    background: var(--400);
    border: 2px solid var(--400);
    color: var(--000);
  }
`
