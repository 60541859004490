import styled from 'styled-components'

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  p {
    text-align: center;
    margin: 0px;
  }
  .sim-wrapper {
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
  }
`
