import Image from 'next/legacy/image'
import { motion } from 'framer-motion'
import CardImg from 'src/assets/images/home-about.png'
import {
  Section,
  Container,
  CardWrapper,
  CardImage,
  CardInfo,
  CardColor01,
  CardColor02,
  CardColor03,
  CardColor04,
} from './styles'

export const About = () => {
  return (
    <Section>
      <Container>
        <CardWrapper
          as={motion.div}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ amount: 0.5 }}
        >
          <CardImage>
            <CardColor01 style={{ background: '#FCD156' }} className="shapes" />
            <CardColor02 style={{ background: '#FFDA72' }} className="shapes" />
            <CardColor03 style={{ background: '#FFDA72' }} className="shapes" />
            <CardColor04 style={{ background: '#FCD156' }} className="shapes" />
            <Image
              src={CardImg}
              alt="#"
              objectFit="cover"
              layout="fill"
              style={{ zIndex: 99 }}
            />
          </CardImage>
          <CardInfo>
            <p className="info-title-01">Conheça o </p>
            <p className="info-title-02">Crédito One.</p>
            <p>
              Contamos com a experiência de anos no mercado financeiro para
              tornar a venda de crédito mais intuitiva, moderna e confiável.
              Temos uma equipe formada por especialistas prontos para te
              atender.
            </p>
          </CardInfo>
        </CardWrapper>
      </Container>
    </Section>
  )
}
