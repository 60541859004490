import React, { FC } from 'react'
import { LogoHorizontal } from 'src/assets/brand'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import {
  Main,
  Container,
  Wrapper,
  Nav,
  NavLinks,
  NavLogo,
  NavSocial,
  Divider,
  Info,
  Copywrite,
  Link,
} from './styles'

const links = [
  {
    label: 'Sobre',
    url: '/sobre',
  },
  {
    label: 'Ajuda',
    url: '/ajuda',
  },
  {
    label: 'Temos de Uso',
    url: '/termos-de-uso',
  },
]

export const Footer: FC = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <Nav>
            <NavLogo href="/">
              <LogoHorizontal />
            </NavLogo>
            <NavLinks>
              {links.map((link) => (
                <Link href={link.url} key={link.url}>
                  {link.label}
                </Link>
              ))}
            </NavLinks>
            <NavSocial>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/souonecredito"
              >
                <FacebookIcon />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/creditosouone/"
              >
                <InstagramIcon />
              </a>
            </NavSocial>
          </Nav>
          <Divider />
          <Info>
            <p>
              Souone.com.br pertence à One Soluções financeiras Ltda, registrada
              sob o CNPJ 27.896.085/0001-94, com sede na Avenida Almirante Júlio
              de Sá Bierrenbach 65, Bloco 1, Sala 315 - Rio de Janeiro, RJ. O
              Crédito One é uma plataforma de crédito digital que funciona como
              correspondente bancário da ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE
              CRÉDITO DIRETO S/A, que é autorizada a funcionar pelo Banco
              Central do Brasil.Os correspondentes bancários são empresas
              contratadas por instituições financeiras e demais instituições
              autorizadas pelo Banco Central do Brasil para a prestação de
              serviços de atendimento aos clientes e usuários dessas
              instituições.
            </p>
            <p>
              A análise de crédito e cálculo das taxas de juros dependem de
              informações fornecidas por você, como dados pessoais, valor
              solicitado e número de parcelas. Toda avaliação será realizada
              conforme nossa política de crédito. Uma vez aprovada a proposta,
              antes de assinar o contrato, o cliente será informado de forma
              completa e transparente quanto a taxa de juros utilizada, tarifas
              aplicáveis, impostos (IOF) e o custo efetivo total (CET). O prazo
              de pagamento mínimo é de 3 meses e o máximo de 18 meses e o Custo
              efetivo total (CET) pode variar de 628,02% a 2.187,16% ao ano.
            </p>
            <p>
              A taxa de juros varia entre 12% e 17,7% ao mês (aproximadamente
              289,60% a 666,69% ao ano). O atraso ou não pagamento de prestações
              do contrato de empréstimo pessoal pode ter consequências legais,
              tais como a inclusão de nome nos cadastros dos órgãos de proteção
              ao crédito, o protesto de títulos e o ajuizamento de ações de
              cobrança. Para evitar maiores problemas e restrições ao seu
              crédito, mantenha sempre o pagamento de suas parcelas em dia.
            </p>
          </Info>
          <Divider />
          <Copywrite>© 2022 One Soluções Financeiras Ltd.</Copywrite>
        </Wrapper>
      </Container>
    </Main>
  )
}
