import http from '@lib/http'

type Simulation = {
  cpf: string
  name: string
  email: string
  amount: string
  installments?: string
  dueDate?: string
  birth: string
  occupation: string
  phone: string
  terms: boolean
}

export async function postEngineSimulation(data: Simulation, config?: any) {
  return await http.post('/api/engine', data, config)
}
