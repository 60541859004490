import styled from 'styled-components'
import { Colors, Breakpoints } from 'styles'

export const Section = styled.div`
  padding: 60px 20px;
  min-height: 40vh;
  background: ${Colors.basic.white};
`

export const Container = styled.div`
  max-width: 791px;
  margin-left: auto;
  margin-right: auto;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
`

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  @media (max-width: ${Breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${Breakpoints.mobilePortrait}) {
    grid-template-columns: 1fr;
  }
`
export const Card = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${Colors.brand.primary};
`

export const CardIcon = styled.div``

export const CardTitle = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
`

export const CardParagraph = styled.div`
  flex-grow: 1;
  color: ${Colors.basic.ground};
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1px;
`

export const CardLink = styled.a`
  color: ${Colors.basic.dark};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
    opacity: 0.96;
  }
  transition: ease opacity 200ms;
`

export const CtaWrapper = styled.div`
  padding: 0px 24px;
  height: 240px;
  max-height: 240px;
  width: 100%;
  /* max-width: 791px; */
  display: flex;
  align-items: flex-end;
  gap: 40px;
  background: ${Colors.brand.primary};
  border-radius: 24px;
  @media (max-width: ${Breakpoints.mobilePortrait}) {
    align-items: center;
    justify-content: center;
  }
`

export const CtaImage = styled.div`
  height: 100%;
  max-height: 240px;
  width: 50%;
  position: relative;
  @media (max-width: ${Breakpoints.mobilePortrait}) {
    display: none;
  }
`

export const CtaInfo = styled.div`
  width: 50%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  justify-content: center;
  @media (max-width: ${Breakpoints.mobilePortrait}) {
    width: auto;
  }
`

export const CtaInfoText = styled.div`
  color: ${Colors.basic.white};
  text-align: center;
  font-size: 24px;
`
