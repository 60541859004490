import styled, { css } from 'styled-components'
import { IButton } from '.'

const variant = {
  primary: {
    default: css`
      background: var(--brand-base);
      color: var(--000);
      border-color: var(--brand-base);
    `,
    hover: css`
      background: var(--brand-base);
      color: var(--000);
      border-color: var(--brand-base);
      &:hover {
        background: transparent;
        color: var(--brand-base);
      }
    `,
    outline: css`
      background: transparent;
      color: var(--brand-base);
      border: 2px solid var(--brand-base);
      &:hover {
        background: var(--brand-base);
        color: var(--000);
      }
    `,
  },
  secondary: {
    default: css`
      background: var(--brand-secondary);
      color: var(--000);
      border-color: var(--brand-secondary);
    `,
    hover: css`
      color: var(--brand-secondary);
      border-color: var(--brand-secondary);
      color: var(--000);
      &:hover {
        background: transparent;
        color: var(--brand-secondary);
      }
    `,
    outline: css`
      background: transparent;
      color: var(--brand-secondary);
      border: 2px solid var(--brand-secondary);
      &:hover {
        background: var(--brand-secondary);
        color: var(--000);
      }
    `,
  },
  highlight: {
    default: css`
      background: var(--brand-highlight);
      color: var(--000);
      border-color: var(--brand-highlight);
    `,
    hover: css`
      background: var(--brand-highlight);
      border-color: var(--brand-highlight);
      color: var(--000);
      &:hover {
        background: transparent;
        color: var(--brand-highlight);
      }
    `,
    outline: css`
      background: transparent;
      color: var(--brand-highlight);
      border: 2px solid var(--brand-highlight);
      &:hover {
        background: var(--brand-highlight);
        color: var(--000);
      }
    `,
  },
}

export const MyButton = styled.button<IButton>`
  /* DEFAULT SETTINGS */
  height: var(--btn-height);
  width: 100%;
  max-width: var(--btn-max-width);
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: var(--btn-radius);
  border: var(--btn-border) solid;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  transition: ease background 160ms, color 160ms, opacity 160ms;
  &:hover {
    cursor: pointer;
    opacity: 0.96;
  }

  /* PRIMARY (DEFAULT) */
  ${variant.primary.default}
  ${({ hover, outline }) =>
    hover ? variant.primary.hover : outline ? variant.primary.outline : null}

  /* SECONDARY */
  ${({ secondary }) => secondary && variant.secondary.default}
  ${({ secondary, hover, outline }) =>
    secondary && hover
      ? variant.secondary.hover
      : secondary && outline
      ? variant.secondary.outline
      : null} 
      
  /* HIGHLIGHT */
  ${({ highlight }) => highlight && variant.highlight.default}
  ${({ highlight, hover, outline }) =>
    highlight && hover
      ? variant.highlight.hover
      : highlight && outline
      ? variant.highlight.outline
      : null}
`
