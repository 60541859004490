export const formatPhone = (value: string) => {
  if (!value) return value
  const phone = value.replace(/[^\d]/g, '')
  const phoneLength = phone.length
  if (phoneLength < 3) return phone
  if (phoneLength < 8) {
    return `(${phone.slice(0, 2)}) ${phone.slice(2)}`
  }
  return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7, 11)}`
}

export const formatCPF = (value: string) => {
  if (!value) return value
  const cpf = value.replace(/[^\d]/g, '')
  const cpfLength = cpf.length
  if (cpfLength < 4) return cpf
  if (cpfLength < 7) {
    return `${cpf.slice(0, 3)}.${cpf.slice(3)}`
  }
  if (cpfLength < 10) {
    return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6)}`
  }
  return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(
    9,
    11
  )}`
}

export const formatDate = (value: string) => {
  if (!value) return value
  const date = value.replace(/[^\d]/g, '')
  const dateLength = date.length
  if (dateLength < 3) return date
  if (dateLength < 5) {
    return `${date.slice(0, 2)}/${date.slice(2)}`
  }
  return `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4, 8)}`
}

export const formatCurrency = (value: number) => {
  if (!value) return value

  const formattedValue = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
  }).format(value)

  return formattedValue.replace('R$', '')
}
