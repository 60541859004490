import { Navbar, Footer } from 'src/layouts'
import { Header } from 'src/components'
import { Faq } from './sections'
import styled from 'styled-components'
import AjudaHeaderImage from 'src/assets/images/ajuda-header.png'

const NavWrapper = styled.div`
  position: absolute;
  top: 0%;
  right: 0%;
  bottom: auto;
  left: 0%;
  z-index: 99;
`

const headerTitle = 'Como podemos ajudar?'

export const Ajuda = () => {
  return (
    <>
      <NavWrapper>
        <Navbar bg="none" />
      </NavWrapper>
      <Header title={headerTitle} image={AjudaHeaderImage} priority />
      <Faq />
      <Footer />
    </>
  )
}
