import React, { FC, useState, KeyboardEvent, MouseEvent } from 'react'
import Link from 'next/link'
import * as gtag from '@lib/gtag'

import { ButtonMedium, ButtonOutline } from 'src/atoms'
import { LogoHorizontal } from 'src/assets/brand'
import { Drawer } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import LanguageIcon from '@mui/icons-material/Language'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  DrawerWrapper,
  ListButton,
  ListItem,
  Main,
  Container,
  NavItems,
  NavLink,
  NavLogo,
  IconButton,
  Wrapper,
} from './styles'

interface Props {
  bg?: 'none'
  color?: 'dark' | 'black'
}

const items = [
  {
    label: 'Início',
    url: '/',
    type: 'navLink',
    icon: <HomeIcon />,
  },
  {
    label: 'Sobre',
    url: '/sobre',
    type: 'navLink',
    icon: <LanguageIcon />,
  },
  {
    label: 'Ajuda',
    url: '/ajuda',
    type: 'navLink',
    icon: <HelpOutlineIcon />,
  },
  {
    label: 'Simule agora',
    url: '/simulacao',
    type: 'navButton',
    icon: <ArrowForwardIcon />,
  },
]

type Anchor = 'right'

interface ICustomButton {
  btnColor: 'dark' | 'primary' | 'white'
}

export const Navbar: FC<Props> = ({ bg, color }) => {
  const [state, setState] = useState(false)

  const handleClick = () => {
    gtag.event({
      action: 'simulation_start',
      category: '',
      label: 'navbar_button',
      value: 0,
    })
  }

  const toggleDrawer =
    (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setState(open)
    }

  const list = (
    <DrawerWrapper
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {items.map((item) =>
        item.type === 'navLink' ? (
          <Link key={item.url} href={item.url}>
            <ListItem>
              {item.icon}
              {item.label}
            </ListItem>
          </Link>
        ) : (
          <Link key={item.url} href={item.url}>
            <ListItem className="cta" onClick={handleClick}>
              {item.icon}
              {item.label}
            </ListItem>
          </Link>
        )
      )}
    </DrawerWrapper>
  )

  const verifyBtnColor = () => {
    switch (color) {
      case 'black':
        return 'dark'
      default:
        return 'white'
    }
  }

  const btnColor = verifyBtnColor()

  return (
    <>
      <Main bg={bg}>
        <Container>
          <Wrapper color={color}>
            <NavLogo href="/">
              <LogoHorizontal />
            </NavLogo>
            <NavItems>
              {items.map((item) =>
                item.type === 'navLink' ? (
                  <Link href={item.url} key={item.url}>
                    <NavLink>{item.label}</NavLink>
                  </Link>
                ) : (
                  <Link
                    href="/simulacao"
                    key={item.url}
                    onClick={handleClick}
                    passHref
                  >
                    <ButtonOutline color={btnColor}>Simule agora</ButtonOutline>
                  </Link>
                )
              )}
            </NavItems>
            <IconButton onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Wrapper>
        </Container>
      </Main>
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        {list}
      </Drawer>
    </>
  )
}
