import styled from 'styled-components'
import { Colors, Breakpoints } from 'styles'

export const Main = styled.div`
  padding: 0px 20px;
  background: var(--000);
`

export const Wrapper = styled.div`
  margin: 80px 0px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 60px;
  @media (max-width: ${Breakpoints.mobileLandscape}) {
    grid-template-columns: 1fr;
  }
`

export const Divider = styled.div`
  margin: 0px auto;
  max-width: 1140px;
  height: 1px;
  background: ${Colors.basic.cloud};
`

export const Ball = styled.div`
  align-self: center;
  position: relative;
  height: 400px;
  width: 400px;
  background: ${Colors.brand.primary};
  border-radius: 200px;
  overflow: hidden;
  @media (max-width: ${Breakpoints.mobileLandscape}) {
    display: none;
  }
`

export const ImageWrap = styled.div`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  height: 80%;
  width: 80%;
`
