import styled from 'styled-components'
import { Colors } from 'styles'

interface IProps {
  color: 'error' | 'warning' | 'success'
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`

export const FormWrapper = styled.div<IProps>`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  background: ${({ color }) => (color ? Colors.system[color] + '24' : 'none')};
  border: 2px solid ${({ color }) => (color ? Colors.system[color] : 'none')};
  border-radius: 4px;
  p {
    text-align: center;
    margin: 0px;
    max-width: 320px;
  }
  .heading {
    font-weight: 500;
  }
  .sim-wrapper {
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
  }
`
