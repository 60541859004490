import { Container } from 'src/components'
import { Section, Wrapper, Divider } from './styles'

export const Content = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <div>
            <p className="h2 title">Termos de uso e Política de Privacidade</p>
            <p>Versão 005 - Última Atualização: 22 de Novembro de 2021</p>
          </div>
          <Divider />
          <div>
            <p className="upper-bold">
              AO CLICAR EM “LI E CONCORDO” OU AO NAVEGAR EM NOSSA PLATAFORMA,
              VOCÊ CONCORDA COM ESTES TERMOS DE USO. SE VOCÊ NÃO CONCORDAR COM
              TODOS OS TERMOS QUE SE SEGUEM, NÃO PODERÁ UTILIZAR OU ACESSAR A
              PLATAFORMA A QUALQUER TÍTULO.
            </p>
            <p>
              {`Para a sua comodidade, a data da última revisão é incluída no topo desta página. Recomendamos que toda vez que você acessar a Plataforma Sou One, fique atento às novas atualizações, pois o seu acesso e uso da Plataforma estarão vinculados por quaisquer alterações destes Termos de Uso.

                O Usuário deve ler este documento antes de acessar a Plataforma Sou One, uma vez que o seu uso ou acesso implica aceitação integral de todos os termos e obrigações previstos neste Termo de Uso, sujeitando-se o Usuário a todas as obrigações e responsabilidades nele previstas.

                A Crédito One poderá estabelecer termos de uso específicos, aplicáveis a determinado serviço e/ou aplicativo, que complementarão e/ou prevalecerão sobre este Termo de Uso, conforme o caso.`}
            </p>
          </div>
          <Divider />
          {topics.map((item) => (
            <div key={item.id}>
              <p className="upper-bold">{item.title}</p>
              <p>{item.text}</p>
            </div>
          ))}
        </Wrapper>
      </Container>
    </Section>
  )
}

const topics = [
  {
    id: 1,
    title: '1) Definições',
    text: `1.1) Para os fins deste Termo de Uso, consideram-se:

    Autenticador de Dados: empresa terceirizada que presta serviços de autenticação de dados bancários e geração de relatórios para a análise de crédito dos seus usuários.
    
    Instituição Financeira Parceira: ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A, com sede na Rua Guilhermina Guinle 272, sala 801, Botafogo, Rio de Janeiro/RJ, CEP 22270-060, inscrita no CNPJ/MF sob no 37.414.009/0001-59.
  
    Crédito One: a sociedade de responsabilidade limitada One Soluções financeiras Ltda. (isoladamente “Crédito One”), inscrita no CNPJ nº 27.896.085/0001-94, com sede na Avenida Almirante Júlio de Sá Bierrenbach 65, Bloco 1, Sala 315 - Rio de Janeiro, RJ, CEP 22775-028.
    
    Participantes: pessoas jurídicas ou quaisquer outros tipos de entidades, fundos, consórcios, sociedades e afins, com elevado grau de sofisticação e conhecimento do mercado financeiro que poderão adquirir da Instituição Financeira Parceira as Cédulas de Crédito Bancário (“CCBs”) emitidas em seu favor pelos Usuários.
    
    Plataforma Sou One: o domínio eletrônico www.souone.com.br e o aplicativo Crédito One, bem como todos os recursos e ferramentas que lhe são relacionadas, além de outros domínios que utilizem, de forma autorizada, a marca Crédito One.
    
    Usuário: todas as pessoas físicas que utilizarem a Plataforma Sou One com a finalidade de tomar empréstimos.`,
  },
  {
    id: 2,
    title: '2) Plataforma Sou One',
    text: `2.1) Somos um correspondente bancário e possuímos uma plataforma online que facilita o acesso de clientes a produtos e serviços ofertados por instituições financeiras parceiras. Os correspondentes bancários são empresas contratadas por instituições financeiras e demais instituições autorizadas pelo Banco Central do Brasil para a prestação de serviços de atendimento aos clientes e usuários dessas instituições, nos termos do disposto na Resolução CMN no 3954, de 24 de novembro de 2011, conforme alterada.

    2.1.1) Para usar os serviços da Plataforma Sou One, você precisa ter 18 (dezoito) anos ou mais.
    
    2.2) Após o seu registro, a Crédito One lhe fará algumas perguntas e solicitará algumas informações para que seja possível intermediar a sua solicitação de crédito junto à Instituição Financeira. No entanto, a operação de crédito é firmada diretamente com a Instituição Financeira, na qualidade de instituição autorizada a conceder crédito nos termos da legislação e regulamentação aplicáveis (“Operação de Crédito”). A Crédito One não é parte da Operação de Crédito.`,
  },
  {
    id: 3,
    title: '3) Cadastro do Usuário',
    text: `3.1.1) Para que os usuários possam desfrutar dos serviços prestados pela Crédito One na Plataforma, faz-se necessário o seu prévio cadastro, contendo informações para acesso à Plataforma, tais como nome de usuário (login) e senha, bem como outras informações adicionais solicitadas. A Crédito One lhe fornecerá um código de ativação (via e-mail) para que você possa validar seu cadastro na Plataforma.

    3.1.2) Você é responsável por manter a confidencialidade de seus dados de login e senha, criados no ato de registro na Plataforma. Você não poderá transmitir a terceiros o seu login e senha, e é única e exclusivamente responsável por todas as atividades que ocorram sob sua conta, por isso, tenha cuidado ao usar redes e computadores públicos e/ou de terceiros, cuja segurança não seja razoável e/ou gravar senhas de forma automática em computadores. Você concorda em notificar a Crédito One imediatamente se suspeitar de qualquer utilização não autorizada de seu login e senha. A Crédito One não será responsável por quaisquer perdas e danos resultantes de acessos não autorizados ou uso de sua conta em caso de descuido ou negligência do usuário na confidencialidade do login e senha.
    
    3.1.3) Você concorda, ainda, em não utilizar o login e senha de outro usuário, a qualquer momento.
    
    3.1.4) Portanto, ao usar a Plataforma e suas funcionalidades você garante que: (a) todas as informações e conteúdos enviados à Crédito One são verdadeiros, exatos, atuais e completos; (b) conservará e atualizará imediatamente tais informações e conteúdos para mantê-los verdadeiros, exatos, atuais e completos, sempre que houver qualquer alteração em suas informações e conteúdos; (c) ao usar esta Plataforma você não viola qualquer lei ou regulamento aplicável; (e) não realizará engenharia reversa da Plataforma; e (f) respeitará os Direitos de Propriedade Intelectual (conforme definido abaixo) da Crédito One, entre outros.
    
    3.1.5) Se qualquer informação fornecida pelo usuário for falsa, incorreta, desatualizada ou incompleta, ou caso a Crédito One tenha razões suficientes para suspeitar que tal informação seja falsa, incorreta, desatualizada ou incompleta, a Crédito One terá o direito de suspender ou cancelar imediatamente, independente de notificação prévia, a conta de cadastro do usuário e recusar toda e qualquer utilização, presente ou futura do serviço, ou parte dele.
    
    3.1.6) Caso você tenha qualquer motivo para acreditar ou suspeitar que qualquer informação que temos a seu respeito está errada ou incompleta, pedimos que, por favor, entre em contato com nossa central de atendimento ou escreva um e-mail o quanto antes para nossa equipe no endereço resolva@souone.com.br.
    
    3.2) Responsabilidade do usuário
    
    3.2.1) O Usuário é exclusivamente responsável:
    
    (i) por todos e quaisquer atos ou omissões por ele incorridos a partir de seu acesso na Plataforma Sou One;
    
    (ii) por outorgar procurações em favor da Crédito One para que os documentos assinados digitalmente possam ser assinados fisicamente, se for o caso, pela Crédito One em seu nome;
    
    (iii) pela guarda e utilização da combinação de senha de acesso e login – necessários para acesso ao conteúdo restrito da Plataforma Sou One –, os quais são pessoais e intransferíveis, sendo o Usuário integralmente responsável pela sua guarda, sigilo e boa utilização, não se responsabilizando a Crédito One pelo uso indevido da senha e login por terceiros;
    
    (iv) pela veracidade, exatidão, completude e precisão de toda e qualquer informação fornecida à Crédito One, comprometendo-se a usar apenas os dados pessoais próprios;
    
    (v) pela reparação de todos e quaisquer danos, diretos ou indiretos (inclusive decorrentes de violação de quaisquer direitos de outros Usuários, de terceiros, inclusive direitos de propriedade intelectual, de sigilo e de personalidade), que sejam causados à Crédito One, a qualquer outro Usuário, ou, ainda, a qualquer terceiro, inclusive em virtude do descumprimento do disposto neste Termo de Uso ou em virtude de qualquer ato praticado por meio do acesso à Plataforma Sou One; e
    
    (vi) por manter seu computador seguro, através de ferramentas como antivírus, firewall, dentre outras, contribuindo, assim, para a prevenção de riscos eletrônicos.
    
    3.3) Em nenhuma hipótese, a Crédito One será responsável:
    
    (i) por qualquer ato ou omissão incorrido e/ou dano causado pelo Usuário em função de seu acesso à Plataforma Sou One;
    
    (ii) pelo uso indevido da Plataforma Sou One por qualquer Usuário ou terceiros, bem como pelos conteúdos carregados, enviados e/ou transmitidos à Plataforma Sou One pelo Usuário; e/ou
    
    (iii) por falhas, impossibilidades técnicas ou indisponibilidades do sistema.
    
    3.4) O Usuário declara estar ciente de que todos e quaisquer conteúdos enviados e/ou transmitidos à Plataforma Sou One por qualquer Usuário e/ou terceiros não representam, de nenhuma forma, a opinião ou a visão da Crédito One.
    
    3.5) Caso o Usuário descumpra quaisquer disposições deste termo de uso, sem prejuízo de outras medidas cabíveis, a Crédito One poderá, a qualquer tempo e a seu exclusivo critério, sem necessidade de qualquer aviso ou notificação prévia ou posterior a qualquer usuário ou a terceiro, suspender ou limitar o acesso à Plataforma Sou One, encerrar a conta de qualquer usuário e/ou tomar outras providências que entender necessárias a fim de garantir o cumprimento deste termo de uso e o bom funcionamento da Plataforma Sou One.
    
    3.6) Quanto aos prazos de armazenamento:
    
    3.6.1) Dados cadastrais do usuário deverão ser armazenados até 5 anos após o término da relação, de acordo com o Art. 12 e 34 do Código de Defesa do Consumidor.
    
    3.6.2) Os dados de identificação digital serão mantidos por 6 meses, de acordo com Art. 15, Marco Civil da Internet.
    
    3.6.3) Os outros dados do usuário serão armazenados enquanto durar a relação e não houver pedido de apagamento ou revogação de consentimento, de acordo com o Art. 9, Inciso II da Lei Geral de Proteção de Dados Pessoais.
    `,
  },
  {
    id: 4,
    title: '4) Empréstimos concedidos através da Plataforma Sou One',
    text: `4.1) Por meio da Plataforma Sou One, o Usuário terá acesso a determinados serviços e produtos do mercado financeiro e aos meios eletrônicos de pagamento, especialmente ao serviço de concessão de crédito, prestado pela Instituição Financeira Parceira.

    4.2) O Usuário não necessitará de abrir conta corrente na Instituição Financeira Parceira para que possa tomar empréstimos por meio da Plataforma Sou One.
    
    4.3) O Usuário interessado em contratar um empréstimo deverá informar o seu interesse, especificando, dentre as opções oferecidas pela Plataforma Sou One, o valor, condições de prazo e pagamento. O usuário declara expressamente estar de acordo com o tratamento de seus dados pessoais para finalidade específica, em conformidade com a Lei nº 13.709- Lei Geral de Proteção de Dados (LGPD), assim deverá fornecer as informações pessoais solicitadas pela Plataforma Sou One, as quais, juntamente com informações de bases públicas e privadas de dados, servirão de fundamento para a análise do risco de crédito do Usuário. Informamos que a ACESSO DIGITAL TECNOLOGIA DA INFORMAÇÃO S.A. (UNICO) receberá e armazenará seus dados pessoais e biométricos para promover maior segurança no uso de sua identidade, de modo a prevenir a ocorrência de fraudes e o uso indevido dos seus dados. Se desejar mais informações, acesse: https://unico.io/privacidade-e-gestao-de-dados/.
    
    4.3.1) O Usuário poderá ser requisitado a informar, dentre outras informações pessoais, os dados referentes a suas contas bancárias abertas em instituições financeiras, tais como: dados da instituição bancária, número de agência bancária, número de conta, bem como eventuais outros dados de contas correntes, contas de investimento e contas poupança do Usuário. Neste caso, a Plataforma Sou One utilizará os serviços de um Autenticador de Dados, cujo algoritmo de criptografia garante a confidencialidade e segurança dos dados divulgados pelo Usuário, tendo em vista a utilização de protocolo SSL (Secure Socket Layer).
    
    4.3.1.1) A Crédito One, a Instituição Financeira Parceira e seus empregados não terão acesso aos dados de acesso às contas bancárias do Usuário, que serão processados diretamente pelo sistema do Autenticador de Dados para geração dos relatórios da situação financeira do Usuário.
    
    4.3.1.2) Os dados referidos no Item 4.3.1 serão utilizados única e exclusivamente para fins de visualização dos extratos bancários e correspondente análise do risco de crédito do Usuário através do acesso às suas informações financeiras pessoais geradas pelo sistema do Autenticador de Dados.
    
    4.3.1.3) A Crédito One, a Instituição Financeira Parceria, o Autenticador de Dados ou qualquer outro terceiro envolvido no processo de avaliação de crédito e concessão do empréstimo ao Usuário não movimentarão ou de qualquer forma interferirão nas contas bancárias e nos ativos do Usuário, devendo observar estritamente o disposto neste Termo de Uso.
    
    4.3.1.4) Ao concordar com este Termo de uso, o Usuário autoriza a Crédito One, a Instituição Financeira Parceira, o Autenticador de Dados e qualquer outro terceiro envolvido no processo de avaliação de crédito e concessão de empréstimo a ter acesso às informações financeiras pessoais do Usuário mantidas junto às Instituições Financeiras cujos dados foram fornecidos na Plataforma Sou One.
    
    4.3.2) O Usuário que desejar obter uma proposta de crédito com a Instituição Financeira Parceira, por meio da Plataforma Sou One, deverá (i) autorizar o acesso da Instituição Financeira Parceira às informações que lhe são relativas e que constem do Sistema de Informações de Crédito do Banco Central (“SCR”), órgãos de proteção ao crédito, bases públicas e privadas de dados, sistemas de internet banking cujos dados foram fornecidos pelo Usuário nos termos do Item 4.3.1 e demais consultas às informações pertinentes ou a quaisquer outras fontes que possam auxiliar na definição do risco de crédito do Usuário; (ii) autorizar o compartilhamento das informações coletadas pela Instituição Financeira Parceira com a Crédito One e os Participantes, conforme previsto no Item 4.4; e (iii) autorizar a execução de procedimentos de Know Your Client (“KYC”) e Anti Money Laundering (“AML”), quando necessário ou adequado.
    
    4.3.3) O usuário que aceitar nossos Termos de Uso, declara que leu e nos autoriza a coletar, utilizar e tratar os seus dados cadastrais, pessoais, como nome ou razão social, número CPF ou CNPJ, e-mail e endereço de cobrança, além de seus dados de identificação digital, tais como Endereço IP e Porta Lógica de Origem, Registros de interações com a Plataforma, Telas acessadas, Dispositivo (versão do sistema operacional) e Geolocalização, para identificar e autenticar; cumprir as obrigações decorrentes do uso dos serviços da Plataforma, realizar prevenção a fraudes, proteção ao crédito e riscos associados, cumprimento de obrigações legais, regulatórias e execução do contrato.
    
    4.3.4) O usuário está ciente de que poderá ter livre acesso a esses dados a qualquer momento. Caso não estejam corretos ou se não corresponderem à verdade, o usuário tem ciência de que poderá pedir a sua atualização, retificação, modificação, o seu cancelamento, gratuitamente, mediante requerimento escrito enviado por email para meajuda@soune.com com o assunto “ALTERAÇÃO DE CADASTRO”.
    
    4.3.5) O usuário responsabiliza-se integralmente pela exatidão e pela veracidade dos dados informados, tendo ciência e estando plenamente de acordo que eventuais divergências e/ou inconsistências desses dados poderão afetar a concessão de crédito, o que poderá impedir o acesso a todos ou a alguns dos serviços oferecidos pela Crédito One em seus sites, como medida de segurança.
    
    4.4) As informações previstas no Item 4.3 e/ou o resultado da análise de crédito do Usuário poderão ser compartilhados entre a Instituição Financeira Parceira, a Crédito One e/ou com terceiros que precisem ter acesso a tais informações, sempre que tal providência seja necessária para a avaliação do risco de crédito e/ou de risco de fraude do Usuário, para a concessão e/ou cobrança do empréstimo.
    
    4.5) A Instituição Financeira Parceira, auxiliada pela Crédito One, efetuará avaliação do risco de crédito do Usuário, com base nas informações por ele fornecidas e/ou obtidas pela Instituição Financeira Parceira e pela Crédito One.
    
    4.6) Com base na avaliação de crédito feita pela Crédito One, o Usuário – caso tenha seu crédito pré-aprovado –, receberá um resumo das condições de seu empréstimo, contendo: (i) valor de principal; (ii) valor da tarifa de cadastro; (iii) valor do Imposto sobre Operações Financeiras (“IOF”); (iv) número de parcelas mensais; (v) data de vencimento das parcelas mensais; (vi) valor das parcelas mensais; (vii) taxa de juros do empréstimo; (viii) Custo Efetivo Total do Empréstimo (“CET”); e (ix) indicação da forma de pagamento das parcelas mensais.
    
    4.7) Caso aceite as condições do crédito pré-aprovado, o Usuário deverá realizar o preenchimento de dados pessoais como dados bancários e endereço residencial, bem como realizar o upload de via digitalizada de (i) seu documento de identificação (RG, CNH ou RNE), (ii) foto frontal do próprio rosto; e (iii) comprovante de endereço residencial, quando necessário. Por último, caso todas as informações prestadas pelo Usuário sejam consideradas válidas, este deverá assinar digitalmente as CCBs representativas do crédito pré-aprovado, que serão emitidas em favor da Instituição Financeira Parceira e formalizarão as condições previstas no Item 4.6.
    
    4.8) O cumprimento das condições impostas pelo Item 4.7 não significa que o Usuário teve seu empréstimo aprovado e/ou que receberá o valor do empréstimo imediatamente. A assinatura digital das CCBs significa apenas que o perfil de crédito do Usuário foi aprovado e que ele confirma sua manifestação de vontade, no sentido de tomar o empréstimo que seria formalizado pelas respectivas CCBs. O cumprimento das condições previstas no Item 4.7 representa, portanto, uma pré-autorização do Usuário para a concessão de empréstimo nas condições constantes das CCBs, ficando a concessão do empréstimo e a eficácia das CCBs assinadas digitalmente condicionadas: (i) à aprovação cadastral do Usuário pela Instituição Financeira Parceira; e (ii) à existência de Participantes interessados em adquirir as respectivas CCBs, que somente gerarão efeitos após o efetivo recebimento do respectivo valor do empréstimo pelo Usuário.
    
    4.9) As CCBs emitidas têm como previsão uma condição suspensiva – consistente na aprovação cadastral do Usuário pela Instituição Financeira Parceira e consistência dos dados bancários para recebimento do valor do empréstimo –, que deverá ser satisfeita em até 2 (dois) dias. Após este prazo, caso a referida condição suspensiva não tenha sido implementada, as CCBs serão automaticamente extintas e canceladas. Nesse caso, o Usuário deverá solicitar o empréstimo novamente, o qual poderá – a depender do mercado de crédito – estar sujeito a novas condições, mais benéficas ou não. O Usuário declara-se ciente de que o cancelamento de uma ou mais CCBs já assinadas digitalmente por ele, não ensejará direito de indenização, aplicação de qualquer tipo de penalidade e/ou afins.
    
    4.10) Na hipótese de efetivação do empréstimo, o Usuário receberá os recursos financeiros correspondentes às CCBs, já líquidos do IOF e da tarifa de cadastro – cobrada pela Instituição Financeira Parceira –, em conta bancária de titularidade do próprio Usuário, conforme indicado em seu cadastro realizado pela Plataforma Sou One.
    
    4.11) O Usuário não se opõe e não se oporá à cessão, aos Participantes, da(s) CCB(s) que vier a emitir inicialmente em favor da Instituição Financeira Parceira. Pelo presente Termo, declara o Emissor, estar ciente que o referido débito será cedido a Amigo Fundo de Investimento em Direitos Creditórios Multicarteira, fundo de investimento em direitos creditórios constituído sob a forma de condomínio fechado, cujo regulamento foi registrado no 4º Registro de Títulos e Documentos Ofício de Registro de títulos e documentos de São Paulo – SP, sob o nº 5230587, em 07 de janeiro de 2014, inscrito no CNPJ/MF sob o nº 13.387.221/0001-06, na forma do art. 290 do Código Civil in verbis:
    
    “Art. 290. A cessão do crédito não tem eficácia em relação ao devedor, senão quando a este notificada; mas por notificado se tem o devedor que, em escrito público ou particular, se declarou “ciente” da cessão feita”.
    
    4.11.1) A Cessionária, possui o direito de inscrever o Usuário em órgãos de proteção ao crédito, caso este se torne inadimplente quanto ao pagamento do empréstimo, cessando tal direito somente quando o Usuário houver quitado integralmente o empréstimo. A negativação do Usuário perante os órgãos de crédito poderá ser feita a qualquer momento em que esteja configurada a inadimplência do Usuário.
    
    4.12) O usuário não poderá excluir sua conta na Plataforma Sou One enquanto não houver quitado integralmente o empréstimo a ele concedido por meio da Plataforma Sou One. Todavia, caso o usuário venha a excluir sua conta – seja por iniciativa própria, determinação judicial ou erro de sistema – continuará obrigado ao pagamento normal do saldo devedor e encargos de seu empréstimo, os quais continuarão a ser normalmente cobrados pela Crédito One.
    
    4.13) Fica ressalvado o direito da Crédito One de manter as informações, dados dos Usuários e/ou dos empréstimos a eles concedidos, cuja manutenção seja imposta à Crédito One em razão de normas contratuais, legais, administrativas, regulamentares e de política interna ou, ainda, cuja manutenção seja necessária para cumprimento de ordem judicial ou administrativa. As referidas informações poderão ser mantidas pela Crédito One, mesmo diante da exclusão da conta do Usuário na Plataforma Sou One.
    `,
  },
  {
    id: 5,
    title: '5) Propriedade Intelectual',
    text: `5.1) Dos direitos da Crédito One

    A logomarca, imagens, textos, nome, domínio eletrônico, além de toda e qualquer expressão de propaganda ou sinal distintivo da Crédito One, presentes na Plataforma Sou One estão protegidos por direitos autorais ou outros direitos de propriedade intelectual de titularidade da Crédito One, não podendo ser usados, sob qualquer meio ou forma, pelos Usuários sem prévia e expressa autorização da Crédito One.
    
    5.2) Reprodução de conteúdo para uso próprio
    
    A reprodução de quaisquer conteúdos da Plataforma é proibida, salvo prévia autorização por escrito da Crédito One, ou nos casos em que se destina ao uso exclusivamente pessoal do usuário. Em nenhum dos casos o usuário adquirirá qualquer Direito de Propriedade Intelectual sobre referidos conteúdos.`,
  },
  {
    id: 6,
    title: '6) Comunicações e Notificações',
    text: `6.1) O usuário ao aceitar estes Termos de Uso autoriza a Crédito One a comunicar-se com ele através de quaisquer meios eletrônicos, incluindo, mas não se limitando, como telefonia celular, torpedo (SMS ou mensagem de texto), Whatsapp, correio eletrônico, bem como via SAC, e, ainda, correspondência física, ficando ressaltado que a principal via de informação para o usuário é a Plataforma.

    6.2) Todas as notificações a qualquer das partes devem ser feitas por escrito, via correio convencional ou eletrônico (e-mail). A Crédito One poderá divulgar notificações ou mensagens por meio da Plataforma para informar o usuário sobre mudanças na Plataforma, nestes Termos de Uso, ou na Política de Privacidade, entre outros assuntos relevantes.`,
  },
  {
    id: 7,
    title: '7) Alteração do Termo de Uso',
    text: `7.1) A Crédito One reserva a si o direito de, a qualquer tempo e sem necessidade de qualquer aviso prévio ou posterior, alterar unilateralmente, a Plataforma Sou One, seu conteúdo, este Termo de Uso e a Política de Privacidade.

    7.2) Qualquer alteração e/ou atualização destes Termos de Uso e da Política de Privacidade passará a vigorar a partir da data de sua publicação na Plataforma Sou One e deverá ser integralmente observada pelos Usuários.`,
  },
  {
    id: 8,
    title: '8) Remuneração da Crédito One',
    text: `8.1) Pela licença de uso da Plataforma Sou One, bem como pelos serviços de avaliação de crédito e intermediação dos pedidos de crédito entre o Usuário e uma Instituição Financeira Parceira através da Plataforma Sou One, o Usuário concorda em pagar à Crédito One um percentual do valor da(s) CCB(s) efetivamente emitida(s) (“Remuneração da Plataforma Sou One”) que será especificado na Plataforma Sou One antes da concretização da operação de empréstimo para que o Usuário expresse seu consentimento. Uma vez ocorrida a concretização da operação, a Remuneração da Plataforma Sou One será cobrada do Usuário na forma do Itens 2.6 e 2.10 acima.

    8.2) Sem prejuízo da obrigatoriedade de pagamento da totalidade dos valores previstos na CCB, o Usuário autoriza a Instituição Financeira Parceira a deduzir o montante equivalente à Remuneração da Plataforma Sou One, bem como quaisquer valores de tributos incidentes sobre a Remuneração da Plataforma Sou One, dos valores a serem efetivamente repassados ao Usuário e/ou ao terceiro indicado pelo Usuário, conforme o caso, por conta e ordem e a pedido do Usuário.`,
  },
  {
    id: 9,
    title: '9) Lei Aplicável e Jurisdição',
    text: `9.1) Os presentes Termos de Uso serão interpretados de acordo com as leis da República Federativa do Brasil. Fica eleito o Foro da Comarca do Rio de Janeiro, Estado do Rio de Janeiro, ou o foro do domicílio do usuário, para dirimir qualquer divergência oriunda dos presentes Termos de Uso, com renúncia expressa a qualquer outro, por mais privilegiado que seja.`,
  },
  {
    id: 10,
    title: '10) Disposições Gerais',
    text: `10.1) Eventual tolerância por parte da Crédito One quanto ao descumprimento de quaisquer das disposições deste Termo de Uso pelo Usuário ou terceiro não constitui novação das obrigações estabelecidas neste Termo de Uso, tampouco constitui renúncia ao direito de exigir o cumprimento da obrigação, a qualquer tempo.

    10.2) Logo após a conclusão do empréstimo, o Usuário receberá as cópias digitais de todos os documentos assinados, as quais serão enviadas para o endereço de e-mail informado pelo Usuário no momento de seu cadastro na Plataforma Sou One.
    
    10.3) O Usuário reconhece que a Crédito One será parte legítima para realizar a cobrança e recebimento mensal relativos ao pagamento das parcelas do empréstimo, de forma que toda e qualquer comunicação, questionamento, dúvida ou relacionamento do Usuário no que se refere ao empréstimo serão realizadas exclusivamente por intermédio da Crédito One (e não diretamente com a Instituição Financeira Parceira).
    
    10.4) A Crédito One informa ao Usuário que está tomando todas as providências necessárias para adequação da sua estrutura, práticas e políticas à Lei Geral de Proteção de Dados (LGPD) – Lei nº 13.853/2019 -, que entrará em vigor em agosto de 2020.`,
  },
]
