import styled from 'styled-components'
import { Colors, Breakpoints } from 'styles'

export const Section = styled.div`
  padding: 60px 20px;
  min-height: 60vh;
  background: ${Colors.system.warning};
`

export const Container = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
`

export const CardWrapper = styled.div`
  padding: 160px 0px;
  position: relative;
  @media (max-width: ${Breakpoints.tablet}) {
    padding: 0px;
  }
`

export const CardImage = styled.div`
  height: 400px;
  max-width: 600px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .shapes {
    @media (max-width: ${Breakpoints.tablet}) {
      display: none;
    }
  }
`

export const CardInfo = styled.div`
  max-width: 400px;
  padding: 24px;
  background: ${Colors.system.warning};
  border-radius: 24px;
  position: absolute;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  left: -24px;
  color: ${Colors.basic.white};
  font-weight: 300;
  font-size: 15px;
  line-height: 1.7;
  letter-spacing: 0.05em;
  .info-title-01 {
    font-size: 40px;
    line-height: 1.3;
  }
  .info-title-02 {
    font-size: 40px;
    line-height: 1.3;
    font-weight: 500;
  }
  @media (max-width: ${Breakpoints.tablet}) {
    border-radius: 0px;
  }
`

export const CardColor01 = styled.div`
  width: 240px;
  height: 240px;
  position: absolute;
  z-index: 90;
  top: 210px;
  left: -90px;
  border-radius: 24px;
`

export const CardColor02 = styled.div`
  background: black;
  width: 240px;
  height: 240px;
  position: absolute;
  z-index: 80;
  top: 270px;
  left: -200px;
  border-radius: 24px;
`

export const CardColor03 = styled.div`
  background: black;
  width: 240px;
  height: 240px;
  position: absolute;
  z-index: 80;
  top: -90px;
  right: -120px;
  border-radius: 24px;
`

export const CardColor04 = styled.div`
  width: 240px;
  height: 240px;
  position: absolute;
  z-index: 60;
  top: -30px;
  right: -200px;
  border-radius: 24px;
`
