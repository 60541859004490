const model = {
  formId: 'simulatorForm',
  formFields: {
    name: {
      name: 'name',
      label: 'Nome completo',
      requiredError: 'Campo obrigatório.',
    },
    phone: {
      name: 'phone',
      label: 'Celular',
      requiredError: 'Campo obrigatório.',
    },
    email: {
      name: 'email',
      label: 'Email',
      requiredError: 'Campo obrigatório.',
    },
    occupation: {
      name: 'occupation',
      label: 'Profissão',
      requiredError: 'Campo obrigatório.',
    },
    cpf: {
      name: 'cpf',
      label: 'CPF',
      requiredError: 'Campo obrigatório.',
    },
    birthDate: {
      name: 'birthDate',
      label: 'Nascimento',
      requiredError: 'Campo obrigatório.',
    },
    amount: {
      name: 'amount',
      label: 'Valor',
      requiredError: 'Insira um valor.',
    },
    installments: {
      name: 'installments',
      label: 'Prazo',
      requiredError: 'Insira um valor.',
    },
    dueDate: {
      name: 'dueDate',
      label: 'Vencimento',
      requiredError: 'Selecione uma opção.',
    },
  },
}

export default model
