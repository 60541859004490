import React from 'react'
import styled from 'styled-components'
import { Navbar, Footer } from 'src/layouts'
import { Header } from 'src/components'
import { Content, Features } from './sections'
import SobreHeaderImage from 'src/assets/images/sobre-header.png'

const NavWrapper = styled.div`
  position: absolute;
  top: 0%;
  right: 0%;
  bottom: auto;
  left: 0%;
  z-index: 99;
`

const headerTitle = `Estamos aqui para criar uma nova experiência no mercado de crédito.`

export const Sobre = () => {
  return (
    <>
      <NavWrapper>
        <Navbar bg="none" />
      </NavWrapper>
      <Header title={headerTitle} image={SobreHeaderImage} />
      <Content />
      <Features />
      <Footer />
    </>
  )
}
