import React from 'react'
import { Navbar, Footer } from 'src/layouts'
import { Content } from './sections'

export const TermosDeUso = () => {
  return (
    <>
      <Navbar bg="none" color="dark" />
      <Content />
      <Footer />
    </>
  )
}
