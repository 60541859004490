import { Navbar, Footer } from 'src/layouts'
import { Header, Content, Features, About, CTA } from './sections'

export const Home = () => {
  return (
    <>
      <Navbar />
      <Header />
      <Content />
      <Features />
      <About />
      <CTA />
      <Footer />
    </>
  )
}
