import { Container } from 'src/components'
import { Section, Wrapper, Box } from './styles'

export const Features = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <div className="h2">Quem somos e o que vendemos.</div>
          <div className="b text">
            Contamos com a experiência de anos no mercado financeiro para
            revolucionar a experiência de quem trabalha com crédito no Brasil.
            Temos uma equipe formada por especialistas nas mais diversas áreas
            para tornar a venda de crédito mais intuitiva, moderna e confiável.
          </div>
          <Box>
            <div className="box-title">Crédito sem garantias</div>
            <div className="text">
              Fugimos das dificuldades e burocracia. Não exigimos garantias para
              liberar o empréstimo.
            </div>
          </Box>
          <Box>
            <div className="box-title">Análise personalizada</div>
            <div className="text">
              Você vale muito mais que um simples score, por isso fazemos uma
              análise personalizada do seu perfil.
            </div>
          </Box>
          <Box>
            <div className="box-title">Assinatura digital</div>
            <div className="text">
              Agora você pode formalizar e assinar seu contrato de onde estiver.
              É simples e bem seguro.
            </div>
          </Box>
        </Wrapper>
      </Container>
    </Section>
  )
}
