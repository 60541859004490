export const LogoIcon = () => (
  <svg
    width="256"
    height="161"
    viewBox="0 0 256 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M104.091 135.769C99.8533 130.275 96.2581 124.315 93.3753 118.007C87.3982 120.022 81.0261 120.587 74.7864 119.656C68.5468 118.726 62.6189 116.326 57.4935 112.655C52.368 108.984 48.1922 104.148 45.3117 98.5471C42.4312 92.9461 40.9288 86.741 40.9288 80.4455C40.9288 74.15 42.4312 67.945 45.3117 62.344C48.1922 56.743 52.368 51.907 57.4935 48.2362C62.6189 44.5654 68.5468 42.1654 74.7864 41.2347C81.0261 40.304 87.3982 40.8694 93.3753 42.8841C99.272 29.9992 108.088 18.6578 119.129 9.75425C107.328 3.34145 94.1025 -0.0124736 80.6653 3.48598e-05C36.1774 3.48598e-05 0 36.0883 0 80.4693C0 124.85 36.1774 160.95 80.6653 160.95C94.1025 160.963 107.328 157.609 119.129 151.196C113.52 146.668 108.472 141.489 104.091 135.769V135.769Z"
      fill="currentColor"
    />
    <path
      d="M255.453 70.5782C253.732 56.629 248.385 43.3743 239.945 32.1349C231.505 20.8955 220.266 12.0639 207.348 6.52023C194.431 0.976567 180.285 -1.08559 166.322 0.539279C152.359 2.16415 139.066 7.41929 127.767 15.781V15.781C122.174 19.9157 117.138 24.7553 112.784 30.1798C108.485 35.51 104.891 41.3715 102.09 47.6198V47.6198C97.449 57.9535 95.0496 69.1527 95.0496 80.4803C95.0496 91.8079 97.449 103.007 102.09 113.341V113.341C106.687 123.57 113.364 132.73 121.695 140.237C123.632 141.984 125.65 143.631 127.749 145.18C141.57 155.44 158.332 160.97 175.545 160.95C215.131 160.95 248.146 132.206 254.8 94.487C256.167 86.5895 256.368 78.5339 255.394 70.5782H255.453ZM212.677 94.487C209.825 102.015 204.751 108.498 198.129 113.076C191.508 117.655 183.65 120.113 175.599 120.124C167.707 120.135 159.994 117.767 153.468 113.329C146.61 108.721 141.364 102.085 138.461 94.3504C135.11 85.405 135.11 75.5496 138.461 66.6043V66.6043C141.368 58.8766 146.614 52.2483 153.468 47.6436C159.772 43.3717 167.177 41.0145 174.79 40.8564C182.403 40.6983 189.9 42.746 196.375 46.7526C201.704 50.0337 206.183 54.5277 209.445 59.8682C212.589 65.0122 214.515 70.8062 215.077 76.8081C215.639 82.8099 214.823 88.8608 212.689 94.4989L212.677 94.487Z"
      fill="currentColor"
    />
  </svg>
)
