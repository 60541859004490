import React, { FC, ButtonHTMLAttributes } from 'react'
import { BtnNav, Spinner } from './styles'

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string
  color?: 'primary' | 'secondary'
  loading?: boolean
}

const ButtonNav: FC<IProps> = ({ children, color, loading, ...props }) => {
  return (
    <BtnNav {...props} option={color}>
      {loading ? <Spinner /> : children}
    </BtnNav>
  )
}

export { ButtonNav }
