import styled, { css } from 'styled-components'
import { Colors } from 'styles'
import { Field } from 'formik'

const CommonStyle = css`
  -webkit-appearance: none;
  padding: 0px 24px;
  width: 100%;
  height: 48px;
  background: ${Colors.basic.cloud};
  color: ${Colors.basic.black};
  font-family: 'Poppins';
  font-size: 16px;
  border: 2px solid ${Colors.basic.cloud};
  border-radius: 48px;
  &:focus {
    border: 2px solid ${Colors.brand.dark};
  }
`

export const Label = styled.label``

export const CustomField = styled(Field)`
  ${CommonStyle};
`

export const HelperMessage = styled.div`
  margin: 4px 24px;
  color: ${Colors.system.error};
  font-size: 14px;
`

export const SelectWrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const Select = styled.select`
  ${CommonStyle};
  &:hover {
    cursor: pointer;
  }
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`
