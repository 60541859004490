import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Colors, Breakpoints } from 'styles'

export const Section = styled.div`
  padding: 60px 20px;
  background: ${Colors.basic.white};
`

export const Container = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
`

export const Title = styled.h2`
  margin: 0px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  color: ${Colors.basic.dark};
  font-size: 40px;
  white-space: pre-line;
  font-weight: 500;
  span {
    color: ${Colors.brand.primary};
  }
`

export const CardWrapper = styled.div`
  display: flex;
  gap: 40px;
  @media (max-width: ${Breakpoints.mobilePortrait}) {
    flex-direction: column;
  }
`

export const Card = styled.div`
  max-width: 565px;
  width: 100%;
`

export const CardImage = styled.div`
  height: 440px;
  margin-bottom: 40px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  @media (max-width: ${Breakpoints.tablet}) {
    height: 320px;
  }
`

export const Cardtext = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
`

export const CardTitle = styled.div`
  margin-bottom: 8px;
  text-transform: uppercase;
  color: ${Colors.brand.primary};
  font-weight: bold;
  letter-spacing: 2px;
`

export const CardParagraph = styled.div`
  color: ${Colors.basic.ground};
  font-size: 15px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 1px;
`
