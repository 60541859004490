import React, { FC, useState, useEffect, MouseEventHandler } from 'react'
import { FormikProps, FormikValues, useFormikContext } from 'formik'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import {
  Wrapper,
  Button,
  MidWrapper,
  InputWrapper,
  Result,
  Label,
  TooltipTrigger,
  Info,
  ResultText,
  SuperWrapper,
} from './styles'
import { Values } from '../../formModel'

interface IProps {
  children: any
}

const InstallmentsField: FC<IProps> = ({ children }) => {
  const [times, setTimes] = useState(12)
  const [isMin, setIsMin] = useState<boolean>(true)
  const [isMax, setIsMax] = useState<boolean>(false)
  const [isHover, setIsHover] = useState<boolean>(false)

  const { values, setFieldValue }: FormikProps<Values> = useFormikContext()

  useEffect(() => {
    if (times === 3) return setIsMin(true)
    setIsMin(false)
    if (times === 18) return setIsMax(true)
    setIsMax(false)
  }, [times])

  useEffect(() => {
    setFieldValue('installments', times.toString())
  }, [times, setFieldValue])

  const handleDecrement = () => {
    if (times > 3) setTimes((previous) => previous - 3)
  }

  const handleIncrement = () => {
    if (times < 18) setTimes((previous) => previous + 3)
  }

  const handleMouseHover = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <Wrapper>
      <Button onClick={handleDecrement} type="button" disabled={isMin}>
        -
      </Button>
      <MidWrapper>
        <Label>Em quantas vezes?</Label>
        <InputWrapper>
          <Result isLoading={values.loading}>
            <ResultText>
              {times}x de {children}
            </ResultText>
            <TooltipTrigger
              onMouseEnter={handleMouseHover}
              onMouseLeave={handleMouseLeave}
            >
              <QuestionMarkCircledIcon />
            </TooltipTrigger>
            <Info hover={isHover}>
              O valor da parcela pode variar de acordo com a análise de crédito.
            </Info>
          </Result>
        </InputWrapper>
      </MidWrapper>
      <Button onClick={handleIncrement} type="button" disabled={isMax}>
        +
      </Button>
    </Wrapper>
  )
}

export { InstallmentsField }
