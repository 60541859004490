import React, { FC, HTMLAttributes, ReactNode } from 'react'
import { Wrapper } from './styles'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export const Container: FC<Props> = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>
}
