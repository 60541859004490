interface IStatusDetails {
  id: 'approved' | 'denied' | 'error' | 'warning'
  color: 'success' | 'warning' | 'error'
  heading: string
  subheading: string
}

export const getStatusDetails = (
  status: 'Aprovado' | 'Recusado' | string
): IStatusDetails => {
  switch (status) {
    case 'Aprovado':
      return {
        id: 'approved',
        color: 'success',
        heading: 'sua simulação de empréstimo foi pré-aprovada!',
        subheading:
          'Entraremos em contato com você o quanto antes para dar continuidade na sua solicitação.',
      }
    case 'Recusado':
      return {
        id: 'denied',
        color: 'error',
        heading: 'no momento não temos uma solução para você.',
        subheading:
          'Fique tranquilo, você pode fazer uma simulação de empréstimo novamente em 30 dias.',
      }
    case 'Duplicata':
      return {
        id: 'warning',
        color: 'warning',
        heading: 'vimos que você já fez uma solicitação com a gente.',
        subheading:
          'Fique tranquilo, você pode fazer tentar novamente em 30 dias. Caso tenha alguma dúvida, fale conosco através de nossos canais oficiais.',
      }
    default:
      return {
        id: 'error',
        color: 'warning',
        heading: 'Tivemos um problema ao processar sua simulação.',
        subheading: 'Favor, tente novamente mais tarde.',
      }
  }
}
