import * as Yup from 'yup'
import moment from 'moment'
import isEmail from 'validator/lib/isEmail'
import simulatorFormModel from './model'
import { isCpf, isValidBirth } from 'src/utils/validator'
import { RE_NON_DIGIT } from 'common/utils/constants'

const {
  formFields: {
    amount,
    installments,
    dueDate,
    name,
    phone,
    email,
    occupation,
    cpf,
    birthDate,
  },
} = simulatorFormModel

export const validationSchema = [
  Yup.object({
    [amount.name]: Yup.string().optional(),
    [installments.name]: Yup.string().optional(),
    [dueDate.name]: Yup.string().optional(),
  }),
  Yup.object({
    [name.name]: Yup.string().required(`${name.requiredError}`),
    [phone.name]: Yup.string()
      .required(`${phone.requiredError}`)
      .min(15, 'Celular deve ter 11 números, incluindo DDD.'),
    [email.name]: Yup.string()
      // .email('Email inválido')
      .required(`${email.requiredError}`)
      .test(
        'is-valid',
        (message: any) => `Email inválido.`,
        (value) =>
          value
            ? isEmail(value, {
                allow_ip_domain: true,
                domain_specific_validation: true,
              })
            : new Yup.ValidationError('Invalid value')
      ),
    [occupation.name]: Yup.string().required(`${occupation.requiredError}`),
  }),
  Yup.object({
    [cpf.name]: Yup.string()
      .required(`${cpf.requiredError}`)
      .test(
        'is-valid',
        (message: any) => `CPF inválido.`,
        (value) =>
          value
            ? isCpf(value.replace(RE_NON_DIGIT, ''))
            : new Yup.ValidationError('Invalid value')
      ),
    [birthDate.name]: Yup.string()
      .required(`${birthDate.requiredError}`)
      .test(
        'is-valid',
        (message: any) => `Verificar data de nascimento.`,
        (value) =>
          value ? isValidBirth(value) : new Yup.ValidationError('Invalid value')
      ),
  }),
]
