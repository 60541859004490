import styled from 'styled-components'
import { Colors } from 'styles'

interface IResultProps {
  isLoading: boolean
}

export const SuperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Wrapper = styled.div`
  position: relative;

  width: 100%;
  display: flex;
  gap: 12px;
  align-items: flex-end;
`

export const Button = styled.button`
  width: 48px;
  height: 48px;
  background: ${Colors.brand.primary};
  border-radius: 50%;
  border: 2px solid ${Colors.brand.primary};
  color: white;
  &:hover {
    cursor: pointer;
  }
  :disabled {
    background: ${Colors.basic.gray};
    border: 2px solid ${Colors.basic.gray};
    cursor: auto;
  }
`

export const MidWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: center;
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins';
`

export const Input = styled.input`
  width: 64px;
  height: 48px;
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;

  border: none;
  outline: none;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export const Label = styled.div``

export const Result = styled.div<IResultProps>`
  color: ${({ isLoading }) =>
    isLoading ? Colors.basic.gray : Colors.brand.black};
`

export const ResultText = styled.div`
  font-size: 1.1875rem;
  font-weight: 600;
  display: inline;
`

export const TooltipTrigger = styled.button.attrs(() => ({ type: 'button' }))`
  display: inline;
  background: none;
`

export const Info = styled.div<{ hover: boolean }>`
  display: ${({ hover }) => (hover ? 'revert' : 'none')};
  /* padding-inline: 48px; */
  margin-block-start: 8px;
  position: absolute;
  left: 48px;
  right: 48px;
  color: var(--600);
  text-align: center;
  font-size: 0.875rem;
`
