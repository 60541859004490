import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Colors, Breakpoints } from 'styles'

export const HeaderSection = styled.div`
  padding: 0px 20px;
  background-image: linear-gradient(to bottom, #444cf0, #467dff);
`

export const Container = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${Breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
  }
  .show {
    animation: showUp 1s;
    @keyframes showUp {
      0% {
        opacity: 0%;
        transform: translateY(90%);
      }
      100% {
        opacity: 100%;
        transform: translateY(0);
      }
    }
  }
`

export const HeaderText = styled.div`
  white-space: pre-line;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`

export const HeaderTitle = styled.h1`
  margin: 0px;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
  color: ${Colors.basic.white};
`

export const HeaderSub = styled.p`
  font-weight: 400;
  color: ${Colors.basic.cloud};
`

export const HeaderCTA = styled.div`
  padding-right: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @media (max-width: ${Breakpoints.tablet}) {
    padding: 0px;
  }
`

export const HeaderBanner = styled.div`
  display: flex;
  align-items: flex-end;
`
