import styled from 'styled-components'
import { Colors } from 'styles'

export const Section = styled.div`
  padding: 20px;
  white-space: pre-line;
  .upper-bold {
    text-transform: uppercase;
    font-weight: bold;
  }
`

export const Wrapper = styled.div``

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${Colors.basic.gray};
`
