import Image from 'next/legacy/image'
import { motion } from 'framer-motion'
import card01 from 'src/assets/images/home-content-card-01.png'
import card02 from 'src/assets/images/home-content-card-02.png'
import {
  Section,
  Container,
  Title,
  Card,
  CardImage,
  CardParagraph,
  CardTitle,
  CardWrapper,
  Cardtext,
} from './styles'

export const Content = () => {
  const variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  }

  return (
    <Section>
      <Container
        as={motion.div}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={variants}
      >
        <Title>
          Crédito rápido
          <span>para quem precisa.</span>
        </Title>
        <CardWrapper>
          <Card>
            <CardImage>
              <Image
                src={card01}
                alt="#"
                layout="fill"
                objectFit="cover"
                // priority={true}
              />
            </CardImage>
            <Cardtext>
              <CardTitle>Sem medo de score</CardTitle>
              <CardParagraph>
                Não se preocupe com score baixo. Analisamos cada pedido de forma
                única para oferecer a parcela ideal para nossos cliente
              </CardParagraph>
            </Cardtext>
          </Card>
          <Card>
            <CardImage>
              <Image src={card02} alt="#" layout="fill" objectFit="cover" />
            </CardImage>
            <Cardtext>
              <CardTitle>Saia do sufoco</CardTitle>
              <CardParagraph>
                Fazemos uma análise personalizada para achar a melhor condição
                para você. Caso sua proposta seja aprovada, o dinheiro cairá na
                sua conta em até 24h.
              </CardParagraph>
            </Cardtext>
          </Card>
        </CardWrapper>
      </Container>
    </Section>
  )
}
