import http from '@lib/http'

export async function getFactors(data: any, config?: any) {
  return await http.post('/api/fatores', data, config)
}

export async function postSimulation(data: any, config?: any) {
  return await http.post('/api/simulacao', data, config)
  /*return new Promise((resolve, reject) => {
    setTimeout(() => resolve(data), 3000)
  })*/
}
