import React, { ChangeEvent, FC } from 'react'
import Link from 'next/link'
import { useFormikContext, FormikValues } from 'formik'
import * as Checkbox from '@radix-ui/react-checkbox'
import { CheckIcon } from '@radix-ui/react-icons'
import { formatCPF, formatDate } from 'src/utils/formatters'
import { TextField } from 'src/atoms/input'
import {
  CheckboxWrapper,
  CustomChecboxRoot,
  CustomCheckboxIndicator,
  FormWrapper,
  Label,
} from './styles'

const Specs: FC = () => {
  const { values, setFieldValue, errors, touched }: FormikValues =
    useFormikContext()

  const firstName = (values.name as string).split(' ')[0]

  const handleBirthChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formatted = formatDate(e.target.value)
    setFieldValue('birthDate', formatted)
  }

  const handleCheck = () => {
    setFieldValue('terms', !values.terms)
  }

  return (
    <FormWrapper>
      <p>
        {firstName}, precisamos apenas de mais algumas informações para concluir
        sua simulação.
      </p>
      <TextField
        name="cpf"
        placeholder="CPF"
        inputMode="numeric"
        touched={touched}
        error={errors.cpf}
        onChange={(e) => {
          const formatted = formatCPF(e.target.value)
          setFieldValue('cpf', formatted)
        }}
      />
      <TextField
        name="birthDate"
        placeholder="Nascimento"
        inputMode="numeric"
        touched={touched}
        error={errors.birthDate}
        onChange={handleBirthChange}
      />
      <CheckboxWrapper>
        <CustomChecboxRoot
          id="sim-terms"
          checked={values.terms}
          onCheckedChange={handleCheck}
        >
          <CustomCheckboxIndicator>
            <CheckIcon />
          </CustomCheckboxIndicator>
        </CustomChecboxRoot>
        <Label htmlFor="sim-terms">
          Eu li e estou ciente das condições de tratamento dos meus dados
          pessoais e do meu consentimento, quando aplicável, conforme descrito
          nos{' '}
          <Link href="/termos-de-uso" target="_blank">
            Termos de Uso.
          </Link>
        </Label>
      </CheckboxWrapper>
    </FormWrapper>
  )
}

export { Specs }
