import React, { FC, useState } from 'react'
import { Wrapper, Button } from './styles'

interface IProps {
  children: any[]
  setFieldValue: (name: string, value: string | number) => void
  option: string
}

const ButtonGroup: FC<IProps> = ({ children, option, setFieldValue }) => {
  const handleClick = (value: number) => {
    setFieldValue('dueDate', value)
  }

  return (
    <Wrapper>
      {children.map((button, index) => (
        <Button
          type="button"
          key={button.id}
          className={index === 0 ? 'first' : index === 3 ? 'last' : ''}
          selected={button.value === option}
          onClick={() => handleClick(button.value)}
        >
          {button.label}
        </Button>
      ))}
    </Wrapper>
  )
}

export { ButtonGroup }
