import React, { FC } from 'react'
import { useFormikContext, FormikValues } from 'formik'
import * as gtag from '@lib/gtag'

import { getStatusDetails } from 'src/utils/simulacao'

import { Main, FormWrapper } from './styles'
import { Button } from '@components/button'

const Result: FC = () => {
  const { values }: FormikValues = useFormikContext()
  const firstName = (values.name as string).split(' ')[0]
  const status = getStatusDetails(values.status)

  const handleClick = () => {
    gtag.event({
      action: 'simulation_contact',
      category: '',
      label: 'wpp_button',
      value: values.amount,
    })
  }

  return (
    <Main>
      <FormWrapper color={status.color}>
        <p className="heading">
          {firstName}, {status.heading}
        </p>
        <p>{status.subheading}</p>
      </FormWrapper>
      {/* {status.id === 'approved' && (
        <Button
          href="https://api.whatsapp.com/send?phone=5521979942359&text=Ol%C3%A1%2C%20acabei%20de%20simular%20um%20empr%C3%A9stimo!"
          type="button"
          onClick={handleClick}
        >
          Falar com atendente
        </Button>
      )} */}
    </Main>
  )
}

export { Result }
