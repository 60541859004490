import styled from 'styled-components'
import { Colors, Breakpoints } from 'styles'
import { Accordion } from '@mui/material'

export const Section = styled.div`
  padding: 60px 20px;
  .faq-title {
    font-weight: 600;
  }
  .faq-text {
    font-size: 15px;
    white-space: pre-line;
  }
  .title {
    text-align: center;
    @media (max-width: ${Breakpoints.mobilePortrait}) {
      text-align: start;
    }
  }
  .subtitle {
    color: ${Colors.brand.primary};
    font-weight: normal;
    text-align: center;
    @media (max-width: ${Breakpoints.mobilePortrait}) {
      text-align: start;
    }
  }
`

export const CustomAccordion = styled(Accordion)`
  padding: 12px 0px;
  box-shadow: none;
  ::before {
    display: none;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
