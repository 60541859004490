import React, { FC, ButtonHTMLAttributes } from 'react'
import { BtnMedium, BtnCTA, BtnOutline } from './styles'

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string
  color?: 'primary' | 'secondary' | 'dark' | 'black' | 'white'
  bg?: 'primary'
  loading?: boolean
}

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string
  color?: 'primary' | 'white' | 'dark'
}

const ButtonMedium: FC<IProps> = ({
  children,
  color,
  bg,
  loading,
  ...props
}) => {
  return (
    <BtnMedium {...props} color={color} bg={bg}>
      {children}
    </BtnMedium>
  )
}

const ButtonOutline: FC<IButtonProps> = ({ children, color, ...props }) => {
  return (
    <BtnOutline {...props} option={color}>
      {children}
    </BtnOutline>
  )
}

const ButtonCTA: FC<IProps> = ({ children, ...props }) => {
  return <BtnCTA {...props}>{children}</BtnCTA>
}

export { ButtonCTA, ButtonMedium, ButtonOutline }
