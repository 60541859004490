import React, { FC, useState, useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/pt'
import { useFormikContext, FormikValues, FormikProps } from 'formik'
import * as Tooltip from '@radix-ui/react-tooltip'
import { formatCurrency } from 'src/utils/formatters'
import { ButtonGroup } from 'src/components'
import { InstallmentsField } from '../../common'
import {
  FormWrapper,
  AmountSliderLabel,
  AmountSliderValue,
  CustomSLider,
} from './styles'

import { getFactors } from '@services/padrao'
import { Values } from '../../formModel'

const Simulator: FC = () => {
  const [amount, setAmount] = useState(1500)
  const [factors, setFactors] = useState<any[]>([{}])

  const { values, setFieldValue }: FormikProps<Values> = useFormikContext()

  moment.locale('pt')
  const today = moment(new Date(), 'DD/MM/YYYY')

  const dueDate = {
    q1: today.clone().add('15', 'days'),
    q2: today.clone().add('20', 'days'),
    q3: today.clone().add('25', 'days'),
    q4: today.clone().add('30', 'days'),
  }

  useEffect(() => {
    setFieldValue('loading', true)
    getFactors({
      Loja: 0,
      Plano: 827,
      Prazo: 0,
      ValorDaParcela: 0,
      ValorDoEmprestimo: values.amount,
      DataSimulacao: 0,
      DataVencimento: Number(values.dueDate) | 0,
      OpcaoDeCalculo: 'ValorDoEmprestimo',
    })
      .then((res) => {
        if (res.status >= 299) {
          throw Error('Fail to connect.')
        }
        setFactors(res.data.data.data.Registros)
        setFieldValue('loading', false)
      })
      .catch(() => setFieldValue('loading', false))
  }, [values.dueDate, values.amount, setFieldValue])

  const buttons = [
    {
      id: 1,
      value: dueDate.q1.format('YYYYMMDD'),
      label: dueDate.q1.format('DD MMM'),
    },
    {
      id: 2,
      value: dueDate.q2.format('YYYYMMDD'),
      label: dueDate.q2.format('DD MMM'),
    },
    {
      id: 3,
      value: dueDate.q3.format('YYYYMMDD'),
      label: dueDate.q3.format('DD MMM'),
    },
    {
      id: 4,
      value: dueDate.q4.format('YYYYMMDD'),
      label: dueDate.q4.format('DD MMM'),
    },
  ]

  const handleSliderCommit = (event: any, value: number | number[]) => {
    setAmount(value as number)
    setFieldValue('amount', value)
  }

  return (
    <FormWrapper>
      <div className="sim-wrapper">
        <AmountSliderLabel>De quanto você precisa?</AmountSliderLabel>
        <AmountSliderValue>R${values.amount}</AmountSliderValue>
        <CustomSLider
          defaultValue={values.amount}
          aria-label="Default"
          valueLabelDisplay="auto"
          onChange={(event, value) => setAmount(value as number)}
          min={500}
          max={3000}
          step={100}
          onChangeCommitted={(event, value) => handleSliderCommit(event, value)}
        />
      </div>
      <InstallmentsField>
        {factors?.map((object) =>
          object?.Prazo?.toString() === values.installments
            ? `R$${formatCurrency(object.ValorParcela)}`
            : ''
        )}
      </InstallmentsField>
      <div className="sim-wrapper">
        <p className="label">Quando deseja começar a pagar?</p>
        <ButtonGroup setFieldValue={setFieldValue} option={values.dueDate}>
          {buttons}
        </ButtonGroup>
      </div>
    </FormWrapper>
  )
}

export { Simulator }
