import React, { useEffect, useRef } from 'react'
import Image from 'next/legacy/image'
import Link from 'next/link'
import * as gtag from '@lib/gtag'

import { motion, useScroll } from 'framer-motion'
import { ButtonCTA } from 'src/atoms'
import headerImg from 'src/assets/images/home-header-01.png'
import {
  Container,
  HeaderBanner,
  HeaderCTA,
  HeaderSection,
  HeaderSub,
  HeaderText,
  HeaderTitle,
  HeaderWrapper,
} from './styles'

export const Header = () => {
  const variants = {
    visible: {
      opacity: 1,
      x: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.25,
      },
    },
    hidden: {
      opacity: 0,
      x: -100,
      transition: {
        when: 'afterChildren',
      },
    },
  }

  const handleClick = () => {
    gtag.event({
      action: 'simulation_start',
      category: '',
      label: 'header_button',
      value: 0,
    })
  }

  return (
    <HeaderSection>
      <Container>
        <HeaderWrapper
          as={motion.div}
          initial="hidden"
          whileInView="visible"
          variants={variants}
          viewport={{ once: true }}
        >
          <HeaderText as={motion.div} variants={variants}>
            <HeaderTitle>{`Crédito Pessoal 
            é com a gente.`}</HeaderTitle>
            <HeaderSub>
              Precisando de dinheiro? Não se desespere, faça uma simulação e
              descubra a parcela ideal para você.
            </HeaderSub>
            <HeaderCTA>
              <Link href="/simulacao" onClick={handleClick}>
                <ButtonCTA style={{ background: '#febc30' }}>
                  Simule agora
                </ButtonCTA>
              </Link>
            </HeaderCTA>
          </HeaderText>
          <HeaderBanner as={motion.div} variants={variants}>
            <Image src={headerImg} alt="#" />
          </HeaderBanner>
        </HeaderWrapper>
      </Container>
    </HeaderSection>
  )
}
