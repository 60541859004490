import React, { FC, useEffect } from 'react'
import Image from 'next/legacy/image'
import { Navbar, Footer } from 'src/layouts'
import { Container } from 'src/components'
import BannerImage from 'src/assets/images/home-cta.png'
import { Form } from './form'
import { Divider, Main, Wrapper, Ball, ImageWrap } from './styles'

export const Simulacao: FC = () => {
  return (
    <>
      <Navbar bg="none" color="black" />
      <Divider />
      <Main>
        <Container>
          <Wrapper>
            <Form />
            <Ball>
              <ImageWrap className="image-wrap">
                <Image
                  src={BannerImage}
                  alt="simulacao"
                  objectFit="contain"
                  layout="fill"
                />
              </ImageWrap>
            </Ball>
          </Wrapper>
        </Container>
      </Main>
      <Divider />
      <Footer />
    </>
  )
}
