import styled from 'styled-components'
import * as Checkbox from '@radix-ui/react-checkbox'

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  p {
    text-align: center;
    margin: 0px;
  }
  .sim-wrapper {
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 16px;

  a {
    font-weight: 500;
    text-decoration: underline;
  }
`

export const CustomChecboxRoot = styled(Checkbox.Root)`
  background-color: white;
  flex: 0 0 25px;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px var(--400);

  :hover {
    background-color: var(--brand-light);
  }
  :focus {
    box-shadow: 0 0 0 2px var(--200);
  }
`

export const CustomCheckboxIndicator = styled(Checkbox.Indicator)`
  color: var(--brand-base);
`

export const Label = styled.label`
  color: var(--600);
  font-size: 0.75rem;
  line-height: 1.3;
`
