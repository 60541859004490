import styled from 'styled-components'
import { Colors, Breakpoints } from 'styles'

export const Section = styled.div`
  padding: 60px 20px;
  background: ${Colors.brand.primary};
`

export const Container = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
`

export const Title = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  color: ${Colors.basic.white};
  font-size: 40px;
`

export const TitleNormal = styled.div``

export const TitleBold = styled.div`
  color: ${Colors.basic.white};
  font-weight: 500;
`

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  /* overflow-x: hidden; */
  @media (max-width: ${Breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${Breakpoints.mobilePortrait}) {
    grid-template-columns: 1fr;
  }
`

export const Card = styled.div`
  padding: 32px 16px;
  /* max-width: 360px; */
  color: ${Colors.basic.white};
  border-radius: 12px;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid white;
  }
  transition: ease border 200ms;
`

export const CardImage = styled.div``

export const CardIcon = styled.div``

export const CardTitle = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
`

export const CardParagraph = styled.div`
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1px;
`

export const CardDivider = styled.div`
  margin: 8px 0px;
  height: 1px;
  background: ${Colors.basic.white};
`
