import { Values } from './types'

export const initialValues: Values = {
  amount: 1200,
  installments: '12',
  dueDate: '',
  name: '',
  phone: '',
  email: '',
  cpf: '',
  birthDate: '',
  occupation: '',
  terms: false,
  token: '',
  status: '',
  loading: false,
}
