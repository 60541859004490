import React, { FC, InputHTMLAttributes, SelectHTMLAttributes } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Label,
  CustomField,
  Select,
  SelectWrapper,
  HelperMessage,
} from './styles'

interface ITextProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  touched: boolean
  error: any
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: any[]
  error: any
  touched: any
  handleChange: any
}

const TextField: FC<ITextProps> = ({ label, touched, error, ...props }) => {
  return (
    <div>
      {label ? <Label>{label}</Label> : null}
      <CustomField {...props} />
      {touched && error && <HelperMessage>{error}</HelperMessage>}
    </div>
  )
}

const SelectField: FC<SelectProps> = ({
  options,
  error,
  handleChange,
  touched,
  ...props
}) => {
  return (
    <SelectWrapper>
      <Select {...props} onChange={handleChange}>
        {options.map((item, index) => (
          <option
            key={item.value}
            value={item.value}
            disabled={index === 0 ? true : false}
          >
            {item.label}
          </option>
        ))}
      </Select>
      {touched && error ? (
        <HelperMessage>{error}</HelperMessage>
      ) : (
        <KeyboardArrowDownIcon />
      )}
    </SelectWrapper>
  )
}

export { TextField, SelectField }
