import styled, { css } from 'styled-components'
import { Colors } from 'styles'

interface IProps {
  color?: 'primary' | 'secondary' | 'dark' | 'black' | 'white'
  bg?: 'primary'
}

interface IButtonProps {
  option?: 'primary' | 'dark' | 'white'
}

const options = {
  primary: css`
    color: ${Colors.brand.primary};
    border: 2px solid ${Colors.brand.primary};
    background: none;
    &:hover {
      background: ${Colors.brand.primary};
      color: ${Colors.brand.white};
    }
  `,
  dark: css`
    color: ${Colors.brand.dark};
    border: 2px solid ${Colors.brand.dark};
    background: none;
    &:hover {
      background: ${Colors.brand.dark};
      color: ${Colors.brand.white};
    }
  `,
  white: css`
    color: ${Colors.brand.white};
    border: 2px solid ${Colors.brand.white};
    background: none;
    &:hover {
      background: ${Colors.brand.white};
      color: ${Colors.brand.primary};
    }
  `,
}

export const BtnMedium = styled.button<IProps>`
  padding: 8px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(p) => (p.bg ? Colors.brand[p.bg] : 'none')};
  color: ${(p) => (p.color ? Colors.brand[p.color] : Colors.basic.white)};
  font-family: 'Poppins';
  font-size: 16px;
  border-radius: 48px;
  border: 2px solid
    ${(p) => (p.color ? Colors.brand[p.color] : Colors.brand.primary)};
  &:hover {
    cursor: pointer;
    color: ${(p) => (p.color ? Colors.brand[p.color] : Colors.brand.primary)};
    background-color: ${Colors.basic.white};
  }
  transition: ease background-color 100ms, color 100ms;
  :disabled {
    background: ${Colors.basic.gray};
    border: 2px solid ${Colors.basic.gray};
    color: ${Colors.basic.white};
  }
`

export const BtnCTA = styled.button`
  padding: 16px 40px;
  width: 100%;
  color: ${Colors.basic.white};
  text-transform: uppercase;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1em;
  border-radius: 64px;
  border: none;
  &:hover {
    cursor: pointer;
    opacity: 0.96;
    background: ${Colors.basic.white};
  }
  transition: ease opacity 100ms;
`

export const BtnOutline = styled.button<IButtonProps>`
  padding: 8px 32px;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ option }) => (option ? options[option] : options.primary)}
  font-family: "Poppins";
  font-size: 16px;
  border-radius: 48px;
  &:hover {
    cursor: pointer;
  }
  transition: ease background-color 100ms, color 100ms;
  :disabled {
    background: ${Colors.basic.gray};
    border: 2px solid ${Colors.basic.gray};
    color: ${Colors.basic.white};
  }
`
