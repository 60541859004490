export const LogoHorizontal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="32.12"
    viewBox="0 0 816.475 218.53"
  >
    <g id="imagotipo-01-branco" transform="translate(-78.725 -376.85)">
      <path
        id="Path_91"
        data-name="Path 91"
        d="M199.37,562.17A106,106,0,0,1,187,541.52a46.065,46.065,0,1,1,0-87.29,106.3,106.3,0,0,1,29.85-38.53,93.51,93.51,0,1,0,0,164.36,105.843,105.843,0,0,1-17.48-17.89Z"
        fill="currentColor"
      />
      <path
        id="Path_92"
        data-name="Path 92"
        d="M376.08,486.37a93.29,93.29,0,0,0-19.49-46.47,93.486,93.486,0,0,0-128.85-17.22h0a93.86,93.86,0,0,0-29.82,37h0a93.22,93.22,0,0,0,0,76.37h0a92.62,92.62,0,0,0,12.41,20.26,93.712,93.712,0,0,0,10.37,11q3.38,3,7,5.75a93.463,93.463,0,0,0,149.03-75.2,95,95,0,0,0-.65-11.49Zm-49.73,27.78a46.11,46.11,0,0,1-43.08,29.79,45.54,45.54,0,0,1-25.71-7.89h0a44.42,44.42,0,0,1-4.25-3.23,45.943,45.943,0,0,1-13.19-51.06h0a46.072,46.072,0,1,1,86.23,32.48Z"
        fill="currentColor"
      />
      <path
        id="Path_93"
        data-name="Path 93"
        d="M474.47,485.33c-3.85,2.62-12.65,3.58-18.56,3.58-27,0-47.16-17.33-47.16-41.94S429,405,455.91,405c5.91,0,14.57,1,18.56,3.57V429h-.69c-4.26-3.71-11.13-4.67-17.32-4.67-13.75,0-23.92,9.62-23.92,22.68s10.17,22.69,23.92,22.69c6.19,0,13.06-1.1,17.32-4.68h.69Z"
        fill="currentColor"
      />
      <path
        id="Path_94"
        data-name="Path 94"
        d="M506.92,486.43H484.23V407.51h21.59v17.05c2.75-6.05,11.14-17.46,22.82-17.46h2.62V432a13.91,13.91,0,0,0-4.4-.69c-12.1,0-19.94,8.39-19.94,14.3Z"
        fill="currentColor"
      />
      <path
        id="Path_95"
        data-name="Path 95"
        d="M582.68,490.29c-30,0-48-18.29-48-43.32s18-43.31,43.86-43.31c24.34,0,42.77,18.29,42.77,43.45V453H558.89c1.93,12.38,10.18,19,24.06,19,11.28,0,19-3.85,25-9.21l12.79,13.61C612.1,484.23,600,490.29,582.68,490.29Zm-23.1-52.53H597c-2.48-9.76-8.94-15.54-18.7-15.54S562.05,428,559.58,437.76Zm27.91-40.7h-18l10.59-20.21H606.2Z"
        fill="currentColor"
      />
      <path
        id="Path_96"
        data-name="Path 96"
        d="M695.29,475.16c-7,8.52-17.47,13.89-30.39,13.89-21.86,0-38.5-18-38.5-41.67S643,404.9,664.9,404.9a37.88,37.88,0,0,1,29,12.92V379.19h22.7V486.43H695.29Zm-23.1-50.6c-12.65,0-22,10-22,22.28s9.35,22.54,22,22.54a22.41,22.41,0,0,0,0-44.82Z"
        fill="currentColor"
      />
      <path
        id="Path_97"
        data-name="Path 97"
        d="M750.83,397.47h-23.1V379.19h23.1Zm-.27,89H727.87V407.51h22.69Z"
        fill="currentColor"
      />
      <path
        id="Path_98"
        data-name="Path 98"
        d="M790,426.62v59.81H767.33V426.62h-9.21V407.51h9.21V388.4L790,384.27v23.24h9.21v19.11Z"
        fill="currentColor"
      />
      <path
        id="Path_99"
        data-name="Path 99"
        d="M895.2,447c0,24.2-19.8,43.32-46.47,43.32S802.39,471.17,802.39,447s19.8-43.31,46.34-43.31S895.2,422.77,895.2,447Zm-69,0c0,13.34,9.62,23.79,22.55,23.79S871.43,460.2,871.43,447s-9.62-23.78-22.68-23.78S826.18,433.77,826.18,447Z"
        fill="currentColor"
      />
      <path
        id="Path_100"
        data-name="Path 100"
        d="M498.12,551.93c0,23.37-18.7,43.45-44.27,43.45s-44.28-20.08-44.28-43.45,18.7-43.45,44.28-43.45S498.12,528.55,498.12,551.93Zm-76,0c0,17.73,13.33,33.13,31.76,33.13s31.76-15.53,31.76-33.13-13.34-33.14-31.76-33.14-31.79,15.4-31.79,33.14Z"
        fill="currentColor"
      />
      <path
        id="Path_101"
        data-name="Path 101"
        d="M520,591.39H508.16V512.46h11.55v9.77c5.08-6.33,14.43-11.83,25.84-11.83,17.47,0,28.33,11.28,28.33,29.56v51.43H562.05v-48c0-17-10-22.41-20.76-22.41-9.07,0-21.31,6.87-21.31,21Z"
        fill="currentColor"
      />
      <path
        id="Path_102"
        data-name="Path 102"
        d="M628.46,595.38c-26.4,0-44.55-19.53-44.55-43.45,0-23.52,17.33-43.45,42.08-43.45a41.69,41.69,0,0,1,41.66,41.93v2.48H596.43c.55,18.84,13.75,32.17,32,32.17a41.78,41.78,0,0,0,30.11-12.78l7.29,7.83C657.89,588.5,645.38,595.38,628.46,595.38Zm-31.07-52.12h57.2c-3-14.16-14.58-24.47-28.6-24.47C611.55,518.79,600.41,528.69,597.39,543.26Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
