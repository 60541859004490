import styled from 'styled-components'
import { Colors } from 'styles'
import { Slider } from '@mui/material'

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  p {
    text-align: center;
    margin: 0px;
  }
  .sim-wrapper {
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
  }
`

export const CustomSLider = styled(Slider)`
  .MuiSlider-thumb {
    color: ${Colors.brand.primary};
    :hover {
      box-shadow: 0px 0px 0px 12px ${Colors.brand.primary}16;
    }
  }
  .MuiSlider-rail {
    height: 8px;
    color: ${Colors.basic.gray};
  }
  .MuiSlider-track {
    height: 8px;
    color: ${Colors.brand.primary};
  }
  .MuiSlider-valueLabel {
    display: none;
  }
`

export const AmountSliderLabel = styled.div``

export const AmountSliderValue = styled.div`
  font-size: 19px;
  font-weight: 600;
`
