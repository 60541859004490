import { FC, ButtonHTMLAttributes } from 'react'
import Link from 'next/link'
import { MyButton } from './styles'

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string
  secondary?: boolean
  highlight?: boolean
  hover?: boolean
  outline?: boolean
  href?: string
}

const Button: FC<IButton> = ({ children, href, ...props }) => {
  return (
    <MyButton as={href ? 'a' : 'button'} href={href} {...props}>
      {children}
    </MyButton>
  )
}

export { Button }
