import Image from 'next/legacy/image'
import Link from 'next/link'
import { motion } from 'framer-motion'
import * as gtag from '@lib/gtag'

import { ButtonCTA } from 'src/atoms'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import WorkIcon from '@mui/icons-material/Work'
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto'
import CtaImg from 'src/assets/images/home-cta.png'
import {
  Section,
  Container,
  Wrapper,
  Card,
  CardIcon,
  CardLink,
  CardParagraph,
  CardTitle,
  CardWrapper,
  CtaImage,
  CtaInfo,
  CtaInfoText,
  CtaWrapper,
} from './styles'

const cards = [
  {
    title: 'Crédito Pessoal',
    text: 'Conheça o Crédito One, nossa filosofia e o que nos move.',
    icon: <AssistantPhotoIcon />,
    url: '/sobre',
  },
  {
    title: 'Ajuda',
    text: 'Tem alguma dúvida? Veja as perguntas mais frequentes e encontre a solução.',
    icon: <HelpOutlineIcon />,
    url: '/ajuda',
  },
  // {
  //   title: 'Consultor One',
  //   text: 'Procurando uma renda extra? Temos a solução ideal para você.',
  //   icon: <WorkIcon />,
  //   url: '/consultor-one',
  // },
]

const handleClick = () => {
  gtag.event({
    action: 'simulation_start',
    category: '',
    label: 'cta_button',
    value: 0,
  })
}

export const CTA = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <CardWrapper>
            {cards.map((card) => (
              <Card key={card.url}>
                <CardIcon>{card.icon}</CardIcon>
                <CardTitle>{card.title}</CardTitle>
                <CardParagraph>{card.text}</CardParagraph>
                <CardLink href={card.url}>Saiba Mais</CardLink>
              </Card>
            ))}
          </CardWrapper>
          <CtaWrapper
            as={motion.div}
            whileInView={{ scale: 1.05, borderRadius: '8px' }}
            viewport={{ amount: 0.8 }}
          >
            <CtaImage>
              <Image
                className="cta-btn-img"
                src={CtaImg}
                alt="#"
                objectFit="contain"
                layout="fill"
              />
            </CtaImage>
            <CtaInfo>
              <CtaInfoText>Peça já o seu empréstimo!</CtaInfoText>
              <Link href="/simulacao" onClick={handleClick}>
                <ButtonCTA style={{ background: '#febc30' }}>
                  Simule agora
                </ButtonCTA>
              </Link>
            </CtaInfo>
          </CtaWrapper>
        </Wrapper>
      </Container>
    </Section>
  )
}
