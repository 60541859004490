import { motion } from 'framer-motion'
import PaymentIcon from '@mui/icons-material/Payment'
import ArticleIcon from '@mui/icons-material/Article'
import PaidIcon from '@mui/icons-material/Paid'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import WorkIcon from '@mui/icons-material/Work'
import {
  Section,
  Container,
  Title,
  TitleBold,
  TitleNormal,
  Card,
  CardWrapper,
  CardDivider,
  CardIcon,
  CardImage,
  CardParagraph,
  CardTitle,
} from './styles'

const cards = [
  {
    title: 'Crédito Pessoal no Cheque',
    text: 'Conseguimos as melhores condições para quem possui cheques.',
    icon: <PaymentIcon />,
  },
  {
    title: 'Crédito Pessoal no Boleto',
    text: 'Dependendo do seu perfil, você pode pagar por boleto bancário.',
    icon: <ArticleIcon />,
  },
  {
    title: 'Conta Bancária',
    text: 'Você precisa ter uma conta no banco para pegar um empréstimo.',
    icon: <AccountBalanceIcon />,
  },
  {
    title: 'Comprovação de Renda',
    text: 'É necessário ter como comprovar sua renda para fazermos uma análise completa.',
    icon: <PaidIcon />,
  },
  {
    title: 'Ocupação',
    text: 'Trabalhamos com Aposentado, Pensionista, Funcionário Público, Militar e Funcionário da Área da Saúde.',
    icon: <WorkIcon />,
  },
]

const variants = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  hidden: {
    opacity: 0,
    x: -50,
    transition: {
      when: 'afterChildren',
    },
  },
}

export const Features = () => {
  return (
    <Section>
      <Container
        as={motion.div}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={variants}
      >
        <Title as={motion.div} variants={variants}>
          <TitleNormal>Estamos aqui</TitleNormal>
          <TitleBold>para te ajudar.</TitleBold>
        </Title>
        <CardWrapper>
          {cards.map((card) => (
            <Card key={card.title} as={motion.div} variants={variants}>
              {card.icon}
              <CardTitle>{card.title}</CardTitle>
              <CardDivider />
              <CardParagraph>{card.text}</CardParagraph>
            </Card>
          ))}
        </CardWrapper>
      </Container>
    </Section>
  )
}
