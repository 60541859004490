import React from 'react'
import { Container } from 'src/components'
import { Section, Wrapper, CustomAccordion } from './styles'

import { AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const faqList = [
  {
    id: 1,
    title: 'Qual a taxa e o prazo?',
    text: `A taxa de juros varia entre 12% e 17,70% ao mês (aproximadamente
    289,60% a 606,83% ao ano) em até 18 parcelas.`,
  },
  {
    id: 2,
    title: 'Quanto tempo demora para receber meu empréstimo?',
    text: `Após a assinatura do contrato e a confirmação de que os documentos enviados estão dentro dos critérios exigidos, o dinheiro será depositado em sua conta em até 1 dia útil.`,
  },
  {
    id: 3,
    title: 'Quais documentos são necessários?',
    text: `Hoje solicitamos o envio dos seguintes documentos:
    - Selfie, lembrando que não pode ser foto da foto.
    - Documento de Identificação com foto, o que inclui RG, CNH ou Carteira de Trabalho.
    - Comprovante de Residência.
    - Comprovante de renda.`,
  },
  {
    id: 4,
    title: 'Quais são as formas de pagamento?',
    text: `O pagamento do empréstimo é feito por cheque.`,
  },
  {
    id: 5,
    title: 'Preciso fazer algum depósito antecipado?',
    text: `O Crédito One não exige nenhum tipo de depósito antecipado para a análise ou liberação do crédito. Se você for contatado por alguém cobrando qualquer valor antecipado em nome da One, entre em contato com a gente imediatamente, pois você pode estar sendo vítima de uma tentativa de fraude. Fique atento, todos os nossos e-mails possuem a extensão @souone.com.br. Percebeu alguma ação estranha? Envie um e-mail para meajuda@souone.com.br ou ligue para (21) 3849-7650.`,
  },
  {
    id: 6,
    title: 'Quais são os canais oficiais da empresa?',
    text: `Fique atento aos nossos canais oficiais de comunicação. Se perceber alguma situação estranha ou desconfiar de alguma mensagem, entre em contato conosco imediatamente.

    Telefone: 21 3849-7650
    Whatsapp: 21 97994-2359
    Email: meajuda@souone.com.br
    Facebook: @souonecredito
    Instagram: @creditosouone`,
  },
]

export const Faq = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <div className="h2 title">Procure em nossos tópicos.</div>
          <div className="h3 subtitle">Perguntas Frequentes</div>
          <div>
            {faqList.map((item) => (
              <CustomAccordion key={item.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="faq-title">{item.title}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="faq-text">{item.text}</div>
                </AccordionDetails>
              </CustomAccordion>
            ))}
          </div>
        </Wrapper>
      </Container>
    </Section>
  )
}
