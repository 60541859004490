import styled from 'styled-components'
import { Colors, Breakpoints } from 'styles'

export const Section = styled.div`
  padding: 96px 20px;
  position: relative;
`

export const Wrapper = styled.div`
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 98;
  font-size: 60px;
  font-weight: 500;
  color: ${Colors.basic.white};
  text-align: center;
  @media (max-width: ${Breakpoints.mobilePortrait}) {
    text-align: start;
    font-size: 48px;
  }
`
