import React, { FC, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import * as fbq from 'common/lib/fpixel'
import * as gtag from '@lib/gtag'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { initialValues, Values, validationSchema } from '../formModel'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import { Simulator, Profile, Specs, Result } from '../formSteps'
import { ButtonNav } from 'src/atoms'
import { Wrapper, FormBox, StepContent, StepNav, StepHeader } from './styles'
import { postEngineSimulation } from '@services/engine'
import { RE_NON_DIGIT } from 'common/utils/constants'
import { useToast } from '@components/ui/use-toast'
import { ToastAction } from '@components/ui/toast'

enum ApprovalStatus {
  approved = 'Aprovado',
  declined = 'Recusado',
}

const Form: FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { toast } = useToast()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activeStep])

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((previous) => previous - 1)
  }

  const handleNext = () => {
    setActiveStep((previous) => previous + 1)
  }

  const trigEvent = (
    event: string,
    status: string,
    occupation: string,
    amount: number
  ) => {
    if (
      occupation == 'Funcionário Público' ||
      occupation == 'Aposentado' ||
      occupation == 'Pensionista' ||
      occupation == 'Militar' ||
      occupation == 'Funcionário da Área da Saúde' ||
      occupation == 'Assalariado'
    ) {
      fbq.custom()
    }
    fbq.event(`${event} ${status}`)
  }

  const handleSubmit = async (
    values: Values,
    actions: FormikHelpers<Values>
  ) => {
    switch (activeStep) {
      case 0:
        await new Promise((resolve) => setTimeout(resolve, 1500))
        actions.setSubmitting(false)
        gtag.event({
          action: 'simulation_nav',
          category: 'step 1',
          label: 'form_button',
          value: values.amount,
        })
        return handleNext()
      case 1:
        executeRecaptcha?.().then((token) => {
          if (!token) return null
          actions.setFieldValue('token', token)
        })
        actions.setSubmitting(false)
        gtag.event({
          action: 'simulation_nav',
          category: 'step 2',
          label: 'form_button',
          value: values.amount,
        })
        return handleNext()
      case 2:
        try {
          const engine = await postEngineSimulation({
            amount: values.amount.toString(),
            installments: values.installments,
            dueDate: moment(values.dueDate, 'YYYYMMDD').format('YYYY-MM-DD'),
            name: values.name,
            phone: values.phone.replace(RE_NON_DIGIT, ''),
            email: values.email,
            cpf: values.cpf.replace(RE_NON_DIGIT, ''),
            birth: moment(values.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            occupation: values.occupation,
            terms: true,
          })

          const status =
            ApprovalStatus[engine.data.message as keyof typeof ApprovalStatus]

          const handleSuccess = () => {
            actions.setFieldValue('status', status)
            trigEvent('Lead', status, values.occupation, values.amount)
            gtag.event({
              action: 'simulation_end',
              category: status.toLowerCase(),
              label: 'form_button',
              value: values.amount,
            })
            gtag.event({
              action: 'simulation_nav',
              category: 'step 3',
              label: 'form_button',
              value: values.amount,
            })
          }

          handleSuccess()

          actions.setSubmitting(false)
          return handleNext()
        } catch (error: any) {
          const { message, status, error: errorType } = error.response.data

          if (status === 404) {
            toast({
              variant: 'destructive',
              title: 'Tivemos um problema: ',
              description: 'Email ou celular já estão cadastrados.',
              action: (
                <ToastAction
                  altText="Try again"
                  onClick={() => setActiveStep(1)}
                >
                  Corrigir
                </ToastAction>
              ),
            })
          }

          actions.setFieldValue('status', 'Error')

          // return handleNext()
        }
      case 3:
        return null
      // return actions.resetForm();
      default:
        return null
    }
  }

  const getStepContent = (activeStep: number) => {
    switch (activeStep) {
      case 1:
        return {
          component: <Profile />,
          backButton: true,
        }
      case 2:
        return {
          component: <Specs />,
          backButton: true,
        }
      case 3:
        return {
          component: <Result />,
          backButton: false,
        }
      default:
        return {
          component: <Simulator />,
          backButton: false,
        }
    }
  }

  const checkDisabled = (terms: boolean, isSubmitting: boolean) => {
    if (activeStep === 2) {
      return terms ? isSubmitting : true
    }
    return isSubmitting
  }

  return (
    <Wrapper>
      <StepHeader>
        <div className="title">Simples. Rápido. Seguro.</div>
        <div className="subtitle">Faça uma simulação agora e descubra.</div>
      </StepHeader>
      <Formik
        initialValues={{
          ...initialValues,
          token: '',
          status: '',
          loading: false,
        }}
        validationSchema={validationSchema[activeStep]}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <FormBox>
            <StepContent>{getStepContent(activeStep).component}</StepContent>
            {activeStep < 3 ? (
              <StepNav>
                <ButtonNav
                  type="button"
                  color="secondary"
                  onClick={handleBack}
                  disabled={!getStepContent(activeStep).backButton}
                >
                  Voltar
                </ButtonNav>
                <ButtonNav
                  className="btn-next"
                  type="submit"
                  loading={isSubmitting}
                  disabled={checkDisabled(values.terms, isSubmitting)}
                >
                  Continuar
                </ButtonNav>
              </StepNav>
            ) : null}
          </FormBox>
        )}
      </Formik>
    </Wrapper>
  )
}

export { Form }
