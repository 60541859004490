import React from 'react'
import Image from 'next/legacy/image'
import { Container } from 'src/components'
import ContentImg from 'src/assets/images/sobre-content.png'
import { Section, Wrapper, TextBody, TextBox, TextTitle } from './styles'

export const Content = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Image src={ContentImg} alt="#" layout="fill" objectFit="cover" />
          <TextBox>
            <TextTitle>
              <div>Faça parte </div>
              <div className="bold">do nosso objetivo.</div>
            </TextTitle>
            <TextBody>
              Queremos entender as necessidades e objetivos de nossos clientes e
              parceiros, oferecendo as melhores soluções financeiras, com
              credibilidade e agilidade, através de uma equipe competente e
              qualificada.
            </TextBody>
          </TextBox>
        </Wrapper>
      </Container>
    </Section>
  )
}
