import styled, { css } from 'styled-components'
import { Breakpoints, Colors } from 'styles'

interface IProps {
  selected?: boolean
}

const styles = {
  standard: css`
    background: none;
    color: ${Colors.brand.primary};
    border: 2px solid ${Colors.basic.gray};
  `,
  selected: css`
    z-index: 9;
    background: ${Colors.brand.primary};
    color: ${Colors.basic.white};
    border: 2px solid ${Colors.brand.primary};
  `,
}

export const Wrapper = styled.div`
  /* isolation: isolate; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: 4px;
  /* overflow: hidden; */
  .first {
    margin-left: 0px;
  }
`

export const Button = styled.button<IProps>`
  position: relative;
  padding: 12px;
  min-height: 40px;
  ${(p) => (p.selected ? styles.selected : styles.standard)}
  font-family: "Poppins";
  margin-left: -2px;
  &:hover {
    z-index: 9;
    cursor: pointer;
    border: 2px solid ${Colors.brand.primary};
    background-color: ${Colors.brand.light};
    color: ${Colors.brand.primary};
  }
  transition: border 160ms ease, background 160ms ease;
`
