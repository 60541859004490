import React, { FC } from 'react'
import { useFormikContext, FormikValues } from 'formik'
import { formatPhone } from 'src/utils/formatters'
import { TextField, SelectField } from 'src/atoms/input'
import { FormWrapper } from './styles'

const Profile: FC = () => {
  const { values, setFieldValue, touched, errors, handleChange }: FormikValues =
    useFormikContext()

  const options = [
    {
      value: '',
      label: 'Selecione sua profissão',
    },
    {
      value: 'Funcionário Público',
      label: 'Funcionário Público',
    },
    {
      value: 'Aposentado',
      label: 'Aposentado',
    },
    {
      value: 'Pensionista',
      label: 'Pensionista',
    },
    {
      value: 'Militar',
      label: 'Militar',
    },
    {
      value: 'Funcionário da Área da Saúde',
      label: 'Funcionário da Área da Saúde',
    },
    {
      value: 'Profissional Liberal',
      label: 'Profissional Liberal',
    },
    {
      value: 'Assalariado',
      label: 'Assalariado',
    },
    {
      value: 'Empresário',
      label: 'Empresário',
    },
    {
      value: 'Autônomo',
      label: 'Autônomo',
    },
    {
      value: 'Outra',
      label: 'Outra',
    },
  ]

  return (
    <FormWrapper>
      <TextField
        id="name"
        name="name"
        placeholder="Nome completo"
        touched={touched}
        error={errors.name}
      />
      <TextField
        id="phone"
        name="phone"
        placeholder="Celular"
        inputMode="tel"
        touched={touched}
        error={errors.phone}
        onChange={(e) => {
          const formatted = formatPhone(e.target.value)
          setFieldValue('phone', formatted)
        }}
      />
      <TextField
        id="email"
        name="email"
        placeholder="Email"
        inputMode="email"
        touched={touched}
        error={errors.email}
      />
      <SelectField
        id="occupation"
        name="occupation"
        options={options}
        handleChange={handleChange}
        touched={touched.occupation}
        error={errors.occupation}
        defaultValue={values.occupation === '' ? '' : values.occupation}
      />
    </FormWrapper>
  )
}

export { Profile }
