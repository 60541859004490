import Link from 'next/link'
import styled from 'styled-components'
import { Colors, Breakpoints } from 'styles'

interface Props {
  bg?: string
  color?: 'dark' | 'black'
}

export const Main = styled.div<Props>`
  padding: 24px;
  min-height: 96px;
  display: flex;
  align-items: center;
  background: ${(p) => (p.bg ? 'none' : Colors.brand.primary)};
`

export const Container = styled.div`
  flex-grow: 1;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
`

export const Wrapper = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  color: ${(p) => (p.color ? Colors.brand[p.color] : Colors.basic.white)};
`

export const NavItems = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  align-items: center;
  @media (max-width: ${Breakpoints.mobileLandscape}) {
    display: none;
  }
`

export const NavLink = styled.div`
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  transition: ease opacity 100ms;
`

export const NavLogo = styled.a`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`

export const IconButton = styled.button`
  display: none;
  padding: 0px;
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${Breakpoints.mobileLandscape}) {
    display: inline;
  }
`

export const DrawerWrapper = styled.div`
  min-width: 250px;
  height: 100%;
  background: ${Colors.basic.dark};
`

export const ListItem = styled.a`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${Colors.basic.white};
  font-size: 14px;
  &.cta {
    background: ${Colors.brand.primary};
  }
  &:hover {
    cursor: pointer;
    background: ${Colors.basic.white}10;
  }
  transition: ease background 200ms;
`

export const ListButton = styled.a``
